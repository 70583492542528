import React, { useState } from 'react';
import { Button, Paper, Slide, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import OfferReward from '../components/OfferReward';
import img from '../assets/images/cafe.png'
import { FiSearch } from 'react-icons/fi';
import { FaRegHeart } from 'react-icons/fa';
import { MdOutlineMyLocation } from 'react-icons/md';
import NearbyBusinessesCard from '../components/NearbyBusinessesCard';
import useAOS from '../customHook/useAOS';

const NearbyBusinesses = () => {
    const [open, setOpen] = useState(true);
    const [locationFlag, setLocationFlag] = useState(false);
    const navigate = useNavigate();


    const handleToggle = () => {
        // setOpen(!open);
    };
    const handleBack = () => {
        if (!locationFlag) {
            navigate(-1);
        }
        else {
            setLocationFlag(false);
        }
    }
    useAOS();


    return (
        <>
            <div data-aos="fade-left" data-aos-duration="500" className='position-fixed w-100 top-0 bg-white z-3' style={{
                height: "59px",
                border: "1.5px solid #CACACA"
            }}>
                <div className=' p-3 d-flex align-items-center'>
                    <p className='font-15 fw-semibold mb-0  flex items-center'><IoClose size={18} onClick={handleBack} />&nbsp;Nearby Businesses</p>
                </div>


            </div>
            {!locationFlag ?
                <section data-aos="fade-left" data-aos-duration="500" className=' z-1 position-relative offerRewards d-flex flex-column' style={{ height: "calc(100vh - 59px)", marginTop: "59px" }}>
                    <div className='offer-search d-flex align-items-center mx-auto px-3 py-2' style={{ width: "90%" }}>
                        <FiSearch size={22} color='#989DA3' />
                        <input type="search" className='form-control manrope' placeholder="Search for a business" />
                        <MdOutlineMyLocation size={28} color='#2ECB71' onClick={() => setLocationFlag(true)} />
                    </div>
                    <div className='p-3'>
                        <NearbyBusinessesCard img={img} heading="Starbucks St." des="Tillary Street, Brunswick East, VIC" distance="2km away" />
                        <NearbyBusinessesCard img={img} heading="Starbucks St." des="Tillary Street, Brunswick East, VIC" distance="2km away" />
                        <NearbyBusinessesCard img={img} heading="Starbucks St." des="Tillary Street, Brunswick East, VIC" distance="2km away" />
                    </div>
                </section> :
                <div data-aos="fade-left" data-aos-duration="500" className='nearby-map' style={{ height: "calc(100vh - 59px)", marginTop: "59px" }} >

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d416737.0901762063!2d148.53879828094838!3d-35.31198131204488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b164ca3b20b34bb%3A0x400ea6ea7695970!2sCanberra%20ACT%2C%20Australia!5e0!3m2!1sen!2s!4v1714222578746!5m2!1sen!2s" width="100%" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <Slide direction="up" className='overflow-y-scroll overflow-x-hidden  ' in={open} mountOnEnter unmountOnExit style={{ height: "300px" }}>
                        <Paper elevation={4} style={{ position: 'fixed', bottom: 0, left: 0, right: 0, padding: 20 }}>
                            <h4 className='fw-bold mb-0'>Nearby</h4>
                            <div className='offer-search d-flex align-items-center w-100 px-3 py-2' >
                                <FiSearch size={22} color='#989DA3' />
                                <input type="search" className='form-control manrope' placeholder="Search for a business" />
                            </div>
                            <OfferReward img={img} head="Store grand opening!" des="Join us at our store opening on the 5.10.24 for free food and drinks all day..." like={true} />

                            {/* <Button onClick={handleToggle} variant="contained" color="secondary">
              Close
            </Button> */}
                        </Paper>
                    </Slide>

                </div>
            }
        </>
    );
};

export default NearbyBusinesses;
