import { Scanner } from '@yudiel/react-qr-scanner'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import useAOS from '../customHook/useAOS';

const QrScanner = () => {
    const [isEnabled , setIsEnabled] = useState(true);
    useAOS();

    const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

    React.useEffect(() => {
        function handleResize() {
            setScreenHeight(window.innerHeight);
        }

        window?.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div data-aos="fade-left" data-aos-duration="500" className='scanner-screen position-relative ' style={{ height: screenHeight }}>
            <div className='scanner-header text-center manrope'>

                <div className='text-white mt-2'>

                    <h2 className='fw-bold ' style={{ fontSize: "35px" }}>
                        Scan the QR code.
                    </h2>
                    <p className='font-13 fw-medium '>Hold your camera facing the timelink device’s QR code.</p>

                </div>
            </div>
            <Scanner
                onResult={(text, result) => {setIsEnabled(false);window.location.href = text;}} enabled={isEnabled}
                onError={(error) => console.log(error?.message)} styles={{ height: "100%" }}
            />
            <div className="cancel-scanner d-flex justify-content-center  manrope">

                <Link to='/letsOrder'>
                    <button type='button' className='w-100 bg-transparent  p-3 rounded-3 font-15 fw-semibold border border-white text-white '>Cancel</button>
                </Link>
            </div>
        </div>
    )
}

export default QrScanner

