import React from "react";
import "../assets/css/versionThree.scss";
import img from "../assets/images/cafe.png";
import { useLocation, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import NearbyBusinessesCard from "../components/NearbyBusinessesCard";
import useAOS from "../customHook/useAOS";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getInteractedBusinesses,
  getOffersAndRewards,
  getUserPointsForBusiness,
  seTgetErrorNull,
} from "../redux/slice/UserSlice";
import { useEffect } from "react";
import { Spinner } from "reactstrap";
import { Box, Modal } from "@mui/material";
import QRCode from "react-qr-code"; 
import { FaStar } from "react-icons/fa";
const OfferRewardCardDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { businessData,offerType } = location?.state || {};
  const dispatch = useDispatch();
  const { isLoading, getError, offersAndRewards, userPoints } = useSelector(
    (state) => state.user
  );
  useAOS();

  // useEffect(() => {
  //   if (getError && getError.length > 1) {
  //     toast.error(getError, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     dispatch(seTgetErrorNull());
  //   }
  // }, [getError]);
  useEffect(() => {
    dispatch(getOffersAndRewards(businessData?.id));
    dispatch(getUserPointsForBusiness(businessData?.id));
  }, []);
  return (
    <>
      <div
        data-aos="fade-left"
        data-aos-duration="500"
        className="position-fixed w-100 top-0 bg-white z-3"
        style={{
          height: "59px",
          border: "1.5px solid #CACACA",
        }}
      >
        <div className=" p-3 ">
          <p className="font-15 fw-semibold mb-0  d-flex align-items-center">
            <IoClose size={18} onClick={() => navigate(-1)} />
            &nbsp;{businessData?.business_name}
          </p>
        </div>
      </div>
      <section
        data-aos="fade-left"
        data-aos-duration="500"
        className=" z-1 position-relative offerRewards d-flex flex-column"
        style={{ height: "calc(100vh - 59px)", marginTop: "59px" }}
      >
        <div className="contianer-fluid p-0 pt-4 flex-grow-1 d-flex flex-column">
            {offerType === 'offer' ?  <p className="font-medium poppins text-center">Offers</p>: <p className="font-medium poppins text-center">Rewards</p>}
         
          <div className=" manrope p-3 pt-0 flex-grow-1">
             {offerType === 'offer' ? <div className="mt-3">
                {isLoading ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <Spinner color="black" size={"md"} />
                  </div>
                ) : (
                  offersAndRewards?.offers?.map((offer) => {
                    return (
                      <div
                        onClick={() =>
                          navigate("/offer/detail", {
                            state: { offer, businessData },
                          })
                        }
                      >
                        <NearbyBusinessesCard
                          img={businessData?.image}
                          heading={offer?.offer_heading}
                          des={offer?.offer_subheading}
                          campaignData={offer}
                        />
                      </div>
                    );
                  })
                )}

                <div onClick={() => navigate("/offer/detail")}>
                  <NearbyBusinessesCard
                    img={img}
                    heading="Store Grand Opening"
                    des="Join us at our store opening on the 5.10.24 for free food and drinks all day..."
                  />
                </div>
                <div onClick={() => navigate("/offer/detail")}>
                  <NearbyBusinessesCard
                    img={img}
                    heading="Store Grand Opening"
                    des="Join us at our store opening on the 5.10.24 for free food and drinks all day..."
                  />
                </div>
              </div>:
              <div className="mt-3">
                {/* {isLoading ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center mt-5">
                    <Spinner color="black" size={"md"} />
                  </div>
                ) : (
                  offersAndRewards?.rewards?.map((reward) => {
                    return (
                      <div
                        className="p-3   rounded-4 manrope mt-3"
                        style={{ border: "1.5px solid rgba(0, 0, 0, 0.05)" }}
                        onClick={() =>
                          navigate("/reward/detail", {
                            state: { reward, businessData },
                          })
                        }
                      >
                        <p className="fw-bolf fw-bold mb-2">
                          {reward?.type == "free_item"
                            ? reward?.item_name + " |"
                            : reward?.discount_percentage
                            ? reward?.discount_percentage + "%"
                            : reward?.discount_amount + "$"}{" "}
                          {reward?.type
                            ?.split("_")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </p>
                        <div
                          className="progress rounded-2"
                          style={{ height: "14px" }}
                          role="progressbar"
                          aria-label="Basic example"
                          aria-valuenow={userPoints?.points}
                          aria-valuemin={0}
                          aria-valuemax={reward?.points_needed}
                        >
                          <div
                            className="progress-bar"
                            style={{
                              width:
                                (userPoints?.points * 100) /
                                  reward?.points_needed +
                                "%",
                            }}
                          />
                        </div>
                        <p className="fw-bolf fw-medium font-12 mb-0 text-black-50 mt-2">
                          {reward?.points_needed} points{" "}
                        </p>
                        <span className="fw-semibold p-2 px-4 d-inline-flex font-15 bg_blue rounded-5 text-white mt-2">
                          View E-Voucher
                        </span>
                      </div>
                    );
                  })
                )} */}

                <div className="p-3 flex items-center gap-3 rounded-4 manrope mb-3 shadow-[0_0_20px_1px_#0000000A]">
                  <div className="flex-1">
                    <h6 className="font-medium text-base mb-1">
                      10% off your entire order
                    </h6>
                    <p className="text-xs fw-medium mb-0 text-gray">
                      Join us at our store opening on the 5.10.24 for free food
                      and drinks all day...
                    </p>
                  </div>
                  <div className=" flex items-center text-xs font-medium gap-1 bg-black text-white p-2 rounded-full"><span className="p-[.12px] w-4 h-4 flex items-center justify-center border-2 border-white rounded-full"><FaStar size={10}/></span>400</div>
                </div>
              </div>}
          </div>
        </div>
      </section>
    </>
  );
};

export default OfferRewardCardDetail;
