import { combineReducers } from 'redux';
import userSlice from './slice/UserSlice';
import updateOrderSlice from './slice/updateOrderSlice';
import surveySlice from './slice/surveySlice';


const rootReducer = combineReducers({
  user: userSlice,
  updateOrder : updateOrderSlice,
  survey : surveySlice
});

export default rootReducer;