import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const ProtectedRoute = () => {
    const token = localStorage.getItem("accessToken"); // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    const [route, setRoute] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        const currentRoute = window.location.pathname.split("/")[2];
        console.log(currentRoute, "===========jih");
        if (currentRoute != 'null') {
            setRoute('/BK/null')
        }
        else{
            setRoute('/launchscreen')
        }
    }, []);
    return token ? (
        <>
            <Outlet />
        </>
    ) : (<Navigate to={route} />
    );
    //     <>
    //       <Outlet />
    //     </>
    //   ) : ( currentRoute == 'https://timelink-user-app.vercel.app/BK/123' ? <Navigate to="/launchscreen" />:
    //     <Navigate to="/" />
    //   );
};

export default ProtectedRoute;
