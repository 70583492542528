import React, { useContext } from "react";
import { AppContext } from "../../pages/QuestGame";

function GameOver() {
  const {
    currentAttempt,
    gameOver,
    rightWord,
  } = useContext(AppContext);
  return (
    <div className="gameOver inter">
      <h5>
        {gameOver.guessedWord
          ? "The word you guessed is correct!"
          : "The word you guessed is wrong!"}
      </h5>
      <h5>The Correct Word is: {rightWord}</h5>
      {gameOver.guessedWord && (
        <h5>You guessed it in {currentAttempt.attempt} attempts.</h5>
      )}

      
    </div>
  );
}

export default GameOver;
