import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import backgroundimg from "../../assets/images/headerscren.png";
import finalLogo from "../../assets/images/finalLogo.png";
import { Box, Button, Modal, Typography } from "@mui/material";
import hands from "../../assets/images/hands.svg";
import { IoChevronBackOutline } from "react-icons/io5";
import loyalty from "../../assets/images/loyalty-img3.png";
import user from "../../assets/images/UserSetting.png";
import '../../assets/css/setting.scss'
import { FaAngleRight } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import userimg from "../../assets/images/SettingModalMbl.png";
import otpimg from "../../assets/images/SettingOtp.png";
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateProfileSetting, updateUserPhoneNumber, verifyUserOTP } from "../../redux/slice/UserSlice";
import { useEffect } from "react";
import close from "../../assets/images/close.png";

const style = {
    position: 'fixed', // Change position to fixed
    bottom: 0, // Align modal to the bottom
    left: '50%',
    transform: 'translateX(-50%)', // Center horizontally
    bgcolor: 'background.paper',
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
    minHeight: "60vh", // Limit modal height to 90% of viewport height
    overflowY: 'auto', // Add scroll when content overflows

};
const Setting = () => {
    const { orderDetail, isLoading, userDetail } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState('');
    const handelback = () => {
        navigate(-1);
    };
    const handleChange = (newValue) => {
        if (newValue) {

            setPhoneNumber(newValue);
        }

    };
    // ---------modals------------
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalTwo, setOpenModalTwo] = React.useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false);
        setOpenModalTwo(true);
    };
    const handlemodalOne = () => {
        setOpenModal(false);

    }
    const handlemodalTwo = () => {
        setOpenModalTwo(false);

    }
    const handleCloseTwo = () => {
        setOpenModalTwo(false);
        setOpenModal(false);
        navigate('/order');
    };

    const handleUpdatePhoneNumber = () => {
        let data = {
            phone: phoneNumber
        }
        dispatch(updateUserPhoneNumber(data, handleClose))
    }
    const handleResponse = (data) => {
        if (data?.status) {
            setOpenModalTwo(false);
            setOpenModal(false);
            navigate('/order');
        }
    }
    const handleOTPVerification = () => {
        let data = {
            otp: otp
        }
        dispatch(verifyUserOTP(data, handleResponse))
    }

    const [smsNotification, setSmsNotification] = useState();
    const [webNotification, setWebNotification] = useState();
    const [whatsappNotification, setWatsappNotification] = useState();

    useEffect(() => {
        dispatch(getUser())
    }, []);
    useEffect(() => {
        if (userDetail?.id) {
            setSmsNotification(userDetail?.show_sms_notification)
            setWebNotification(userDetail?.show_web_notification)
            setWatsappNotification(userDetail?.show_whatsapp_notification)
        }
    }, [userDetail]);

    const handleWhatsappNotification = () => {
        setWatsappNotification(!whatsappNotification)
        let data = {
            show_whatsapp_notification: !whatsappNotification
        }
        dispatch(updateProfileSetting(data))
    }
    const handleWebNotification = () => {
        setWebNotification(!webNotification)
        let data = {
            show_web_notification: !webNotification
        }
        dispatch(updateProfileSetting(data))
    }
    const handleSmsNotification = () => {
        setSmsNotification(!smsNotification)
        let data = {
            show_sms_notification: !smsNotification
        }
        dispatch(updateProfileSetting(data))
    }
    return (
        <>
            <div className="container-fluid p-0 setting pb-4">
                <img src={backgroundimg} className="feedback-Section w-100 " alt="" />
                {/* <Header3/> */}

                <div className="container d-flex justify-content-between align-items-center  my-4">
                    <button className='back_button' onClick={handelback}>{<IoChevronBackOutline />}</button>

                    <img src={finalLogo} width={160} alt="w8" onClick={() => navigate("/order")} />

                    <button className='back_button' style={{ visibility: 'hidden' }}>{<IoChevronBackOutline />}</button>

                </div>


                <div className="whitebg1 mb-5 bg-white mb-4 p-0 rubik">
                    <div className="p-4 d-flex align-items-center justify-content-between cursor-pointer">
                        <div className="d-flex align-items-center">

                            <img src={userDetail?.image} className="setting-user " width={40} height={40} alt="w8" />
                            <p className="font-18  mb-0 ms-2">{userDetail?.phone}</p>
                        </div>
                        <div style={{cursor:'pointer'}} className="text-center lato " onClick={() => navigate('/offers')}>

                            <div className="settingText d-flex align-items-center" style={{ textTransform: 'capitalize' }}><span><img src={loyalty} width={12} height={11} alt="w8" /></span>&nbsp;&nbsp;Loyalty</div>
                        </div>
                    </div>
                    <hr className='border-0 mt-1 mb-1' style={{ height: '.5px', opacity: '50%', backgroundColor: '#CACACA' }} />
                    <div className="p-4">
                        <p className="font-18  text-gray">Account Settings</p>
                        {/* ---EDIT--NUMBER----- */}
                        <div className="d-flex justify-content-between align-items-center pt-2 mt-4"
                            onClick={handleOpen} style={{ cursor: 'pointer' }}>
                            <p className="font-18  mb-0">Edit phone number</p>
                            <FaAngleRight size={18} />

                        </div>


                        <div>

                            {/*------MODAL---PHONE---NUMBER---1 */}
                            <Modal
                                open={openModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                disableScrollLock={true}
                            >
                                <Box className=" d-flex flex-column w-100  setting" sx={style}>
                                    {/* <div className=" text-center pt-0 border-0 position-relative">
                                        <img src={userimg} width={100} height={100} alt="" />
                                        <Button onClick={handlemodalOne}
                                            className="position-absolute top-0 end-0 poppins fw-semibold text-gray font-20" variant="text">X</Button>

                                    </div> */}
                                    <div className="text-end pe-3 pt-3">
                                        <span
                                            role="button" onClick={handlemodalOne}
                                            className=" inter fw-semibold text-gray font-20"
                                        >
                                            <img src={close} width={24} height={24} alt="w8" />
                                        </span>

                                    </div>
                                    <div className="flex-grow-1 d-flex align-items-center px-3">
                                        <div>

                                            <div className="">
                                                <div className="launnch-screen-content">
                                                    <div className="mt-2">
                                                        <p
                                                            className="text-center mx-auto fw-light helvetica-thin " style={{ fontSize: "24px" }}
                                                        >
                                                            Update phone number?
                                                        </p>
                                                        {/* <h5 className="font-30 poppins" style={{ color: '#2A4ECA' }}>Update phone number?</h5> */}

                                                        <p className="font-15 helvetica-light mt-4" style={{ color: "#636366" }}>
                                                            Please provide the new phone number you wish to use for current and future orders.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" mt-3">

                                                <form action="" className="mt-0 num-form w-100">
                                                    <div className="w-100" >
                                                        <div className="phone-number-field  helvetica-light  fw-medium rounded-3 ">
                                                            <p className="mb-0 helvetica-light font-10" style={{ color: "#636366" }}>Enter your phone number (+61412345678)</p>
                                                            <PhoneInput
                                                                className="fw-medium pt-0"
                                                                international
                                                                defaultCountry="AU"
                                                                value={phoneNumber}
                                                                placeholder="Phone Number"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                                {/* <p className=" mt-2 mb-4  inter  font-10" style={{ color: '#3FA9E5' }}>
                                                By providing your phone number you agree to our&nbsp;
                                                <a href="" data-bs-dismiss="modal" className="terms">
                                                    Terms of Service
                                                </a>
                                            </p> */}
                                                <div className="row justify-content-center mt-4">
                                                    <div className="col-7">

                                                        <button
                                                            disabled={phoneNumber.length <= 3}
                                                            type="button" onClick={handleUpdatePhoneNumber}
                                                            className={`btn-fill-${phoneNumber.length > 3 ? 'blue' : 'gray'} p-3 rounded-5 helvetica-medium  w-100 text-white mx-auto font-16 poppins`}
                                                        >
                                                            Continue
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>
                            {/* -------------MODAL----OTP---------- */}
                            <Modal
                                open={openModalTwo}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                disableScrollLock={true}
                            >
                                <Box className=" p-4 px-2 w-100 opt-modal" sx={style}>
                                    {/* <div className=" pt-0 border-0 text-center position-relative">
                                        <img src={otpimg} width={100} height={100} alt="" />
                                        <Button onClick={handlemodalTwo}
                                            className="position-absolute top-0 end-0 poppins fw-semibold text-gray font-20" variant="text">X</Button>

                                    </div> */}
                                    <div className="text-end pe-3 pt-3">
                                        <span
                                            role="button" onClick={handlemodalTwo}
                                            className=" inter fw-semibold text-gray font-20"
                                        >
                                            <img src={close} width={24} height={24} alt="w8" />
                                        </span>

                                    </div>
                                    <div className="flex-grow-1 d-flex align-items-center px-3">

                                        <div >
                                            <div className="">
                                                <div className="launnch-screen-content">
                                                    <div className="mt-2">
                                                        <p
                                                            className="text-center mx-auto fw-light helvetica-thin " style={{ fontSize: "24px" }}
                                                        >
                                                            Enter Verification code
                                                        </p>

                                                        <p className="font-15 helvetica-light mt-4" style={{ color: "#636366" }}>
                                                            We sent a verification code to {phoneNumber}. Please enter the code below.                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3">

                                                {/* <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={5}
                                                renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                                renderInput={(props) => <input {...props} />}
                                            /> */}
                                                <input type="text" placeholder="Verification Code" className="p-3 w-100 rounded-3 helvetica-light" style={{ border: "1px solid #DFDFE4" ,fontSize:"13px"}} maxLength={6} value={otp} onChange={(e) => setOtp(e.target.value)} />

                                                <div className="row justify-content-center mt-4">
                                                    <div className="col-8">

                                                        <Button
                                                            type="button"
                                                            disabled={otp.length === 0}
                                                            className={`btn-fill-${otp.length === 0 ? "gray" : 'blue'} mt-4 p-3 rounded-5  w-100 text-white mx-auto font-16 helvetica-medium`}

                                                            onClick={handleOTPVerification}

                                                        >
                                                            Continue
                                                        </Button>
                                                        <p className=" mt-3 mb-1   font-12 text-center Roobert-regular" style={{ color: '#3B4054' }}>
                                                        Haven't received a verification code?<br />
                                                            <Link

                                                                onClick={() => { setOpenModal(true); setOpenModalTwo(false) }}
                                                                className="terms text-decoration-none"
                                                            >
                                                                Resend OTP
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>

                        </div>
                        {/* ---------MODAl-------END--- */}

                        {/* ----SMS--NOTIFACTION--- */}
                        <div className="d-flex justify-content-between align-items-center pt-2 mt-4">
                            <p className="font-18  mb-0">SMS notifications</p>
                            <label className="switch">
                                <input type="checkbox" checked={smsNotification} onChange={handleSmsNotification} />
                                <span className="slider round" />
                            </label>


                        </div>
                        {/* ---WEB---NOTIFACTION---- */}
                        <div className="d-flex justify-content-between align-items-center pt-2 mt-4">
                            <p className="font-18  mb-0">Web notifications</p>
                            <label className="switch">
                                <input type="checkbox" checked={webNotification} onChange={handleWebNotification} />
                                <span className="slider round" />
                            </label>


                        </div>
                        {/* ---WhATSAPP---NOTIFACTION---- */}
                        <div className="d-flex justify-content-between align-items-center pt-2 mt-4">
                            <p className="font-18  mb-0">Whatsapp notifications</p>
                            <label className="switch">
                                <input type="checkbox" checked={whatsappNotification} onChange={handleWhatsappNotification} />
                                <span className="slider round" />
                            </label>


                        </div>

                    </div>
                    <hr className='border-0 mt-1 mb-1' style={{ height: '.5px', opacity: '50%', backgroundColor: '#CACACA' }} />
                    <div className="p-4">
                        <p className="font-18  text-gray">More</p>
                        {/* ---About us---- */}
                        <div className="d-flex justify-content-between align-items-center pt-2 mt-4">
                            <p className="font-18  mb-0">About us</p>
                            <FaAngleRight size={18} />

                        </div>
                        {/* ---Privacy policy---- */}
                        <div className="d-flex justify-content-between align-items-center pt-2 mt-4">
                            <p className="font-18  mb-0">Privacy policy</p>
                            <FaAngleRight size={18} />

                        </div>
                        {/* ---Terms and conditions---- */}
                        <div className="d-flex justify-content-between align-items-center pt-2 mt-4">
                            <p className="font-18  mb-0">Terms and conditions</p>
                            <FaAngleRight size={18} />

                        </div>
                    </div>







                </div>

            </div>

            {/* <style>
                {`
                                                    .modal - dialog - centered{
           min - height: 100vh ;
           }
                                            .modal-content{
                                                border - bottom - left - radius: 0px !important;
                                            border-bottom-right-radius: 0px !important;

           }
                                            .modal {
                                                --bs - modal - width: 100% !important;
           }
                                            .btn-close{
                                                position: absolute;
                                            top: 7%;
                                            right: 7%;
           }
                                            .modal-header {
                                                padding: 0px 0px 0px 0px !important;
        }
         
         `}
                                        </style> */}
        </>
    );
};

export default Setting;
