import React from 'react'
import '../assets/css/versionThree.scss'
import img from '../assets/images/cafe.png'
import { useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { MdOutlineMyLocation } from 'react-icons/md';
import { FiSearch } from 'react-icons/fi';
import OfferReward from '../components/OfferReward';
import useAOS from '../customHook/useAOS';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getInteractedBusinesses, seTgetErrorNull } from '../redux/slice/UserSlice'
import { useEffect } from 'react';
import { Spinner } from 'reactstrap';
const OfferRewards = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, getError, businessList } = useSelector((state) => state.user);
    useAOS();
    // useEffect(() => {
    //     if (getError && getError.length > 1) {
    //         toast.error(getError, {
    //             position: "top-right",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "light",
    //         });
    //         dispatch(seTgetErrorNull())
    //     }
    // }, [getError])
    useEffect(() => {
        dispatch(getInteractedBusinesses())
    }, [])
    const token = localStorage.getItem('accessToken');
    if (!token) {
        navigate('/orderHistry/withOut-login')
    }
    return (
        <>
            <div data-aos="fade-left" data-aos-duration="500" className='position-fixed w-100 top-0 bg-white z-3' style={{
                height: "59px",
                border: "1.5px solid #CACACA"
            }}>
                <div className=' p-3 '>
                    <p className='font-15 fw-semibold mb-0 flex items-center '><IoClose size={18} onClick={() => navigate(-1)} />&nbsp;Offers & Rewards</p>
                </div>


            </div>
            <section data-aos="fade-left" data-aos-duration="500" className=' z-1 position-relative offerRewards d-flex flex-column' style={{ height: "calc(100vh - 59px)", marginTop: "59px" }}>
                <div className='offer-search d-flex align-items-center mx-auto px-3 py-2' style={{ width: "90%" }}>
                    <FiSearch size={22} color='#989DA3' />
                    <input type="search" className='form-control manrope' placeholder="Search for a business" />
                    <MdOutlineMyLocation size={28} color='#2ECB71' />
                </div>

                <div className='contianer-fluid px-3 flex-grow-1 d-flex flex-column'>

                    <div className=''>
                        {
                            isLoading ?
                                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                    <Spinner color='black' size={'md'} />
                                </div>
                                :
                                businessList?.length > 0 &&
                                businessList?.map((business) => {
                                    return (
                                        <div onClick={() => navigate("/offerRewards/card/detail",{state:{businessData:business,offerType: "offer"}})}>
                                            <OfferReward img={business?.image} head={business?.business_name} des={business?.city} like='like' distance="Calculating" />
                                        </div>
                                    )
                                })
                        }

                        {/* <div onClick={() => navigate("/offerRewards/card/detail")}>
                            <OfferReward img={img} head="Store grand opening!" des="Brunswick East" like='like' distance="2km away" />
                        </div>
                        <div onClick={() => navigate("/offerRewards/card/detail")}>
                            <OfferReward img={img} head="Store grand opening!" des="Brunswick East" like='like' distance="2km away" />
                        </div>
                        <div onClick={() => navigate("/offerRewards/card/detail")}>
                            <OfferReward img={img} head="Store grand opening!" des="Brunswick East" like='like' distance="2km away" />
                        </div> */}




                    </div>
                </div>



            </section>
            <ToastContainer />
        </>
    )
}

export default OfferRewards
