import React from "react";
import { ImSpinner } from "react-icons/im";
import { BsCircleFill } from "react-icons/bs";
import { MdDone } from "react-icons/md";

const Steppers = ({ status, notified }) => {
  console.log(status, notified, "===================status");
  const renderIcon = (stepStatus, stepNumber) => {
    if (stepStatus === "Complete") {
      return <MdDone />;
    } else if (stepStatus === "nothing") {
      return <BsCircleFill />;
    } else {
      return <ImSpinner />;
    }
  };

  return (
    <div className="container">
      <div className="ferozibg mt-3 mb-3">
        <div className="texts d-flex justify-content-between align-items-center mb-3 inter">
          <div className="ps">
            <p
              className={`font-10 fw-medium mb-0 ${status === "linked" ? "active" : "gray"
                }`}
            >
              Linked
            </p>
          </div>
          <div className="ps">
            <p
              className={` font-10 mb-0 ${status === "Pending" ? "active font-14" : "gray"
                }`}
            >
              Preparing
            </p>
          </div>
          <div className="ps">
            <p
              className={` font-10 fw-medium mb-0 ${status === "Notified" ? "active font-14" : "gray"
                }`}
            >
              Ready
            </p>
          </div>
          <div className="ps">
            <p
              className={`font-10 fw-medium mb-0 ${status?.includes('Complete') ? "active font-14" : "gray"
                }`}
            >
              Picked up
            </p>
          </div>
        </div>
        <div className="padding-div position-relative">
          {/* Linked */}
          <div className="line">
            <div
              className={`bg-blue position-relative z-3`}
            >
              {renderIcon("Complete", 1)}
            </div>
          </div>
          {/* Preparing */}
          <div
            className={`bg-blue  position-relative z-3 ${notified == 1 ? "" : "myloader"
              } ${status === "Pending" ? "scale-up" : ""}`}
          >
            {notified == 1 ? <MdDone /> : renderIcon("Pending", 2)}
          </div>
          {/* Ready */}
          <div
            className={`bg-blue position-relative z-3 
             ${notified == 1 ? "" : "myloader"} ${status === "Notified" ? "scale-up" : ""
              }`}
          >
            {/* {status === 'pickedup' ?  <ImSpinner /> : renderIcon('completed', 3)} */}
            {notified == 1 ? (
              renderIcon("Complete", 2)
            ) : (
              <BsCircleFill size={10} />
            )}
            {/* <BsCircleFill size={10}/> */}
          </div>
          {/* Picked Up */}
          <div
            className={`bg-blue position-relative z-3 ${status === "Complete" ? "scale-up " : "myloader"
              }`}
          >
            {status === "Complete" ? (
              <MdDone size={17} />
            ) : status === "Notified" ?  (
              <ImSpinner />
            ) : (<BsCircleFill size={10} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steppers;
