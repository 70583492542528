/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { linkUserWithOrder, seTgetErrorNull, signInWithGoogle } from "../redux/slice/UserSlice";
// import { dispatch } from "../redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FaCircleNotch } from "react-icons/fa";
import Toaster from "../components/Toaster";
import logo from '../assets/images/TimeLinkLogoBlackText.png'
// import { getFirebaseToken, onMessageListener } from "../../src/firebase";
import { FcGoogle } from "react-icons/fc";
// import { messaging } from '../firebase';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginSocialGoogle } from "reactjs-social-login";
import useMetaThemeColor from "../customHook/useMetaThemeColor";
// import { messaging } from "../firebase";
// import { getToken, getMessaging, onMessage } from "firebase/messaging";


const Numberbody1 = () => {

  const dispatch = useDispatch();
  const { getError } = useSelector((state) => state.user);

  const [searchParams, setSearchParams] = useSearchParams();
  var id = searchParams.get("orderId");
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState(null);
  const [showButton, setShowButton] = useState(false);
  // console.log(showButton)
  const [phoneNumber, setPhoneNumber] = useState("");
  const [deviceToken, setDeviceToken] = useState("");
  const [email, setEmail] = useState(undefined);
  const [phoneBoxActive, setPhoneBoxActive] = useState(false);

  const [isValidNumber, setIsValidNumber] = useState("");
  const [loading, setLoading] = useState(false); // Step 1: Loading state
  useMetaThemeColor('white');
  // useEffect(() => {
  //   if (getError && getError.length > 1) {
  //     toast.error(getError, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     dispatch(seTgetErrorNull())
  //   }
  // }, [getError])
  useEffect(() => {
    setOrderId(id);
  }, [id]);
  const [showOrderModal, setShowOrderModal] = useState(false);
  useEffect(() => {
    if (id == null || id == "undefined") {
      setShowOrderModal(true);
    }
  }, []);

  const handleInputClick = () => {
    setShowButton(true);
  };

  const handleInputChange = (data) => {
    if (data) {
      setPhoneNumber(data);
      setIsValidNumber(isValidPhoneNumber(data));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let businessKey = localStorage.getItem('businessKey');
    let orderId = localStorage.getItem('orderId');
    localStorage.setItem('phoneNumberForOtp', phoneNumber)
    if (phoneNumber == "") {

    } else {
      let data = {
        phone: phoneNumber,
        device_token: deviceToken,
        businessKey: businessKey,
        orderId: orderId
      };
      if (email) { data.email = email; }
      setLoading(true)
      dispatch(linkUserWithOrder(data, handleResponse));
    }
  };
  const handleResponse = (response) => {
    if (response?.status) {
      if (response?.isOrderFound) {
        localStorage.setItem('isOrderFound', true)
      }
      console.log(response);
      setPhoneNumber("");
      navigate("/otp", { state: phoneNumber });
    } else {

    }
  };
  // useEffect(() => {
  //   getToken(messaging, { vapidKey: 'BDv49OuMS8N-WsFSmF36sXr15w1-R-kwR22_9isA_hgDulty4XuwG3R1I-rQ1QE7dKjF7PDut933i_fLj_V6mPE' }).then((currentToken) => {
  //     if (currentToken) {
  //       console.log('firebase device token', currentToken)
  //       // Send the token to your server and update the UI if necessary
  //       // ...
  //     } else {
  //       // Show permission request UI
  //       console.log('No registration token available. Request permission to generate one.');
  //       // ...
  //     }
  //   }).catch((err) => {
  //     console.log('An error occurred while retrieving token. ', err);
  //     // ...
  //   });
  // }, [])

  // useEffect(() => {
  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     // ...
  //   });
  // }, [])

  // const handleDeviceToken = (data) => {
  //   // dispatch(updateDeviceToken(data))
  //   console.log("divicetoken", data);
  //   setDeviceToken(data)
  // };

  // useEffect(() => {
  //   // setLoading(false);
  //   getFirebaseToken(handleDeviceToken);
  // }, []);
  // onMessageListener()
  //   .then((payload) => {
  //     console.log(payload?.notification?.title, "haschxavsva");
  //     console.log(payload, "payload");
  //   })
  //   .catch((err) => console.log("failed: ", err));

  return (
    <>
      {/* <div className="d-flex justify-content-between flex-column" style={{ minHeight: '100vh' }}> */}
      <div className="d-flex flex-column bg-white" style={{ minHeight: '100vh' }}>

        <div className="d-flex align-items-center w-100 justify-content-center " style={{ flexGrow: "3" }}>
          <div>
            {/* <Header1 /> */}
            <div className="container Roobert-semibold">
              <div className="row justify-content-center">
                <div className="col-11">
                  <div className="launnch-screen-content">
                    <div className="mt-2">
                      {/* <Link to="/letsOrder">
                        <button type='button' className='w-100 btn-fill-blue p-3 rounded-3 font-15 fw-semibold text-blue'>Go to new design</button>
                      </Link> */}
                      <h5 className="fw-semibold Roobert-semibold" style={{ fontSize: "28.8px" }}>What’s your <br />
                        phone number?</h5>

                      <p className="mb-4 font-14 Roobert-regular">
                        Please enter your phone number to receive live order updates.                    </p>

                    </div>
                    <form onSubmit={handleSubmit} className="mt-0 num-form w-100">
                      <div className="w-100" onClick={handleInputClick}>
                        <div className={`phone-number-field w-100 rounded-3 p-0 ${phoneBoxActive ? 'phone-number-field-active' : ''}`}>
                          <PhoneInput
                            className="fw-medium Roobert-medium"
                            international
                            defaultCountry="AU"
                            value={phoneNumber}
                            placeholder="Phone Number"
                            onChange={handleInputChange}
                            onFocus={() => { setPhoneBoxActive(true) }}
                            onBlur={() => { setPhoneBoxActive(false) }}
                          />
                        </div>
                      </div>

                    </form>
                  </div>
                  <Toaster />
                  <div className="mt-3">
                    {
                      <button
                        className={`order-pickup-btn-dspl fw-normal w-100 ${isValidNumber ? "valid" : ""
                          }`}
                        onClick={handleSubmit}
                        disabled={!isValidNumber}
                      >
                        {loading ?
                          (

                            <div className={`myloader`}>
                              <FaCircleNotch />
                            </div>)
                          :
                          "Continue"

                        }
                      </button>
                    }
                  </div>
                  <div className="mt-3 d-flex flex-row justify-content-evenly">
                    <hr style={{ height: 1, borderWidth: 1, width: '30%' }} />
                    <span>OR</span>
                    <hr style={{ height: 1, borderWidth: 1, width: '30%' }} />
                  </div>
                  <div className="mt-4">
                    {
                      <LoginSocialGoogle

                        client_id={'212290965015-r0v8ahr9q13u3upesu87bisjvtoi82n7.apps.googleusercontent.com' || ''}
                        onLoginStart={() => { console.log('oauth 2.0 started') }}
                        onResolve={({ provider, data }) => {
                          console.log(data);
                          var d = {
                            email: data.email,
                            name: data.name,
                          }
                          dispatch(signInWithGoogle(d, (data) => {
                            if (!data?.data.phone) {
                              setEmail(data?.data.email);
                              toast.error('We could not find your phone number from your google account so you are required to provide the phone number!', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                              });
                            } else {
                              if (data?.status) {
                                if (!data?.isOrderFound) {
                                  navigate('/setting')
                                } else {
                                  navigate('/order')
                                }
                              }
                            }
                          }))
                          console.log('data object to be sent in signInWithGoogle', d)
                          //console.log(provider, data)
                        }}
                        onReject={(err) => {
                          console.log(err)
                        }}
                      >
                        <button
                          className={`btn-google w-100 Roobert-bold fw-bold text-dark d-flex flex-row justify-content-between py-3 px-4 rounded-5`}

                        >
                          {loading ?
                            (

                              <div className={`myloader`}>
                                <FaCircleNotch />
                              </div>)
                            :
                            <>
                              <FcGoogle size={20} />
                              <span>Continue with Google</span>
                              <span></span>
                            </>


                          }
                        </button>
                      </LoginSocialGoogle>
                    }
                  </div>
                  <p className="px-3 mt-2 Roobert-regular text-center font-12">
                    By clicking “Continue” you agree to our <Link className="terms text-decoration-none">
                      Terms of Use
                    </Link>  and acknowledge that you have read and understand our <Link className="terms text-decoration-none">Privacy Policy.</Link>

                  </p>
                </div>
              </div>
            </div>

          </div>
          {/* <Bottomcom /> */}
        </div>
        <div className="text-end pb-3 pe-3">
          <img src={logo} width={150} alt="w8" />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Numberbody1;
