import React from 'react'
import { FaCircle } from 'react-icons/fa';

import { MdDone } from "react-icons/md";
const OrderReadyStepper = () => {
    return (
        <>
            <div className="container">
                <div className="ferozibg mt-2 mb-2">
                    <div className="texts d-flex justify-content-between align-items-center mb-3">
                        <div className="ps ">
                            <p className='font-10  font-w-5 mb-0 gray'>Linked</p>

                        </div>
                        <div className="ps">
                            <p className='font-10  font-w-5 mb-0 gray'>Preparing</p>
                        </div>
                        <div className="ps">
                            <p className='font-w-5 mb-0'>Ready</p>
                        </div>
                        <div className="ps">
                            <p  className='font-10  font-w-5 mb-0 gray'>Picked up</p>
                        </div>
                    </div>
                    <div className="padding-div position-relative">
                        <div className="line">

                        <div className="bg-blue position-relative z-3">
                            <MdDone />
                        </div>
                        </div>
                        {/* <span className='horizental-line'></span> */}
                        <div className="bg-blue  position-relative z-3">
                            <MdDone />
                        </div>
                        {/* <span className='horizental-line1' ></span> */}
                        <div className="bg-blue position-relative z-3" style={{transform: 'scale(1.3)'}}>

                            <MdDone />
                        </div>
                        {/* <span className='horizental-line2'></span> */}
                        <div className="bg-blue position-relative z-3">
                            <FaCircle size={13} />
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default OrderReadyStepper
