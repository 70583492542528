import { createSlice } from '@reduxjs/toolkit'
import { dispatch } from '../store/store'
import axios from '../../utils/axiosBaseUrl';

const initialState = {
  isLoading: false,
  createError: null,
  updateError: null,
  getError: null,
  deleteError: null,
  userList: [],
  businessList: [],
  userOrders: [],
  userPoints: {},
  campaigns: null,
  userDetail: {},
  orderDetail: {},
  offersAndRewards: {}
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    //START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    //STOP LOADING
    stopLoading(state) {
      state.isLoading = false
    },
    //HAS CREATE ERROR
    hasCreateError(state, action) {
      state.isLoading = false
      state.createError = action.payload
    },
    //UPDATE ERROR
    hasUpdateError(state, action) {
      state.isLoading = false
      state.updateError = action.payload
    },
    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false
      state.getError = action.payload
    },

    // HAS UPDATE ERROR
    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = action.payload
    },
    // GET User Success
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload
    },

    // GET User DETAILS
    getUserDetailsSuccess(state, action) {
      state.isLoading = false;
      state.userDetail = action.payload
    },

    // GET Campaigns
    getCampaignsSuccess(state, action) {
      state.isLoading = false;
      state.campaigns = action.payload
    },

    getOrderDetailsSuccess(state, action) {
      state.isLoading = false;
      state.orderDetail = action.payload
    },
    getUserOrdersSuccess(state, action) {
      state.isLoading = false;
      state.userOrders = action.payload
    },
    getBusinessListSuccess(state, action) {
      state.isLoading = false;
      state.businessList = action.payload
    },
    getOffersAndRewardsSuccess(state, action) {
      state.isLoading = false;
      state.offersAndRewards = action.payload
    },
    getUserPointsSuccess(state, action) {
      state.isLoading = false;
      state.userPoints = action.payload
    },
  },
})
export const { getUserDetailsSuccess, startLoading, stopLoading } = userSlice.actions;
export default userSlice.reducer

// User Functions

//User Login
export function userLogin(data, handleClose) {
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('user/login',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      handleClose(response.data);
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      console.log(JSON.stringify(response?.data?.data))
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error);
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function signInWithGoogle(data, handleResponse) {
  dispatch(userSlice.actions.hasGetError(''));
  console.log('signInWithGoogle function is called from redux and received this data ' + data)
  return async () => {
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('auth/user/sign-in-with-google',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      } else {
        //console.log(JSON.stringify(response.data))
        dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
        if (response?.data?.data?.phone) {
          localStorage.setItem('accessToken', response.data.access_token)
        }
        localStorage.setItem('userDetails', JSON.stringify(response.data.data))
        dispatch(userSlice.actions.stopLoading());
        handleResponse(response.data)
      }

    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}


//Get User Details
export function getUser() {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('user', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      localStorage.setItem('userDetails', JSON.stringify(response.data.data))
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      localStorage.removeItem('userDetails');
      localStorage.removeItem('accessToken');
      // dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getCampaigns(bid) {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('business/' + bid + '/campaigns', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getCampaignsSuccess(response.data.data));
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getUserOrders() {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('user/orders', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getUserOrdersSuccess(response.data.data));
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getInteractedBusinesses() {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('user/business-list', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getBusinessListSuccess(response.data.data));
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getOffersAndRewards(businessId) {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('business/' + businessId + '/offer-and-rewards', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getOffersAndRewardsSuccess(response.data.data));
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getUserPointsForBusiness(businessId) {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('user/business/' + businessId + '/points', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.getUserPointsSuccess(response.data.data));
    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function getCampaignDetails(cid, handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')
    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.get('campaign/' + cid, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(userSlice.actions.stopLoading());
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      } else {
        handleClose(response.data.data);
      }

    } catch (error) {

      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function linkUserWithOrder(data, handleRespopnse) {
  return async () => {

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post("user/order/link", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      if (!response.data.status) {
        dispatch(userSlice.actions.stopLoading());
        handleRespopnse(response?.data);
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      else {
        handleRespopnse(response?.data);
        dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
      }

      // (res.data);
    } catch (error) {
      dispatch(userSlice.actions.stopLoading());
      handleRespopnse(error);
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function getBusinessOrderData(pageRefreshed = false, handleResponse) {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')
    // dispatch(userSlice.actions.startLoading());
    try {
      let continueRecursive = localStorage.getItem('continueRecursive');
      let orderId = localStorage.getItem('orderId');
      let businessKey = localStorage.getItem('businessKey');
      if (continueRecursive || pageRefreshed) {
        let res = await axios.get("order-by/" + businessKey + "/" + orderId + "", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + accessToken
          },
        })
        if (!res.data.status) {
          console.log(res.data, '=========order response ')
          dispatch(userSlice.actions.stopLoading());
          dispatch(userSlice.actions.hasGetError(res?.data?.message));
          handleResponse(false)
        }
        else {
          console.log(res.data, 'order response ')
          handleResponse(true)
          if (res?.data?.data?.is_notified == 1) {
            localStorage.removeItem('continueRecursive');
          } else {
            localStorage.setItem("continueRecursive", res?.data?.data?.id);
          }
          dispatch(userSlice.actions.getOrderDetailsSuccess(res.data.data));
          dispatch(stopLoading());
        }
      }
      // (res.data);
    } catch (error) {
      localStorage.removeItem('continueRecursive');
      handleResponse(false)
      dispatch(userSlice.actions.stopLoading());
      console.log(error?.message + "try catch======== error");
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function UpdateOrderStatus(data, handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/order/status',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken

        }
      });
      handleClose(response.data);
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      console.log(JSON.stringify(response?.data?.data))
      // dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error);
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}
export function updateUserPhoneNumber(data, handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('user/phone',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken

        }
      });
      handleClose(response.data);
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      console.log(JSON.stringify(response?.data?.data))
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error);
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function updateProfileSetting(data, handleResponse) {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('auth/profile',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken

        }
      });
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      }
      if (response.data.status && handleResponse) {
        handleResponse();
      }
      console.log(JSON.stringify(response?.data?.data))
      localStorage.setItem('userDetails', JSON.stringify(response.data.data))
      dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function verifyUserOTP(data, handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('user/phone/verify',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      handleClose(response.data);
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      } else {
        localStorage.setItem('accessToken', response?.data?.access_token)
        dispatch(userSlice.actions.getUserDetailsSuccess(response.data.data));
        localStorage.setItem('userDetails', JSON.stringify(response.data.data))
      }
    } catch (error) {
      handleClose(error);
      dispatch(userSlice.actions.hasGetError(error?.message));
    }
  };
}

export function submitSurvey(data, handleClose) {
  console.log('Submit survey is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem('accessToken')

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('submit_survey',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      } else {
        handleClose(response.data);
      }
    } catch (error) {
      handleClose(error);
    }
  };
}

export function textMeLink(data, handleClose) {
  console.log('Text me a link is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem('accessToken')

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('campaign/send-user-link',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });
      dispatch(userSlice.actions.stopLoading());
      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      } else {
        handleClose(response.data);
      }
    } catch (error) {
      handleClose(error);
    }
  };
}

export function updateSurveyInteractionStatus(data) {
  console.log('updateSurveyInteractionStatus is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem('accessToken')

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/survey/update-interaction-status',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      } else {
      }
    } catch (error) {
      console.log(error + '--------error in update survey interaction status')
    }
  };
}

export function updateMarketingInteractionStatus(data) {
  console.log('updateMarketingInteractionStatus is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem('accessToken')

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('campaign/add-marketing-interaction',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      } else {
      }
    } catch (error) {
      console.log(error + '--------error in adding marketing interaction status')
    }
  };
}

export function submitFeedback(data, handleClose) {
  console.log('Submit Feedback is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem('accessToken')

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('submit_feedback',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      } else {
        handleClose(response.data);
      }
    } catch (error) {
      handleClose(error);
    }
  };
}
export function addClaimAbleLoyalty(data, handleClose) {
  console.log('add claim able loyalt is called from redux and received this data ' + JSON.stringify(data))
  return async () => {
    let accessToken = localStorage.getItem('accessToken')

    dispatch(userSlice.actions.startLoading());
    try {
      const response = await axios.post('business/add-claim-able-loyalty',
        data, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      if (!response.data.status) {
        dispatch(userSlice.actions.hasGetError(response?.data?.message));
      } else {
        handleClose(response.data);
      }
    } catch (error) {
      handleClose(error);
    }
  };
}
export function seTgetErrorNull() {
  return async () => {
    dispatch(userSlice.actions.hasGetError(null));
  }
}
