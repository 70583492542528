/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessOrderData,
  getUser,
  seTgetErrorNull,
  submitFeedback,
  submitSurvey,
  updateMarketingInteractionStatus,
  UpdateOrderStatus,
  updateSurveyInteractionStatus
} from "../../redux/slice/UserSlice";

import circled from "../../assets/images/circle.svg";
import location from "../../assets/images/location.svg";
import timer from "../../assets/images/timmer.svg";
import refre from "../../assets/images/ref.svg";
import Stepper from "../../components/Steppers";
import { Link, useNavigate } from "react-router-dom";
import backgroundimg from "../../assets/images/headerscren.png";
import finalLogo from "../../assets/images/finalLogo.png";
import greentick from "../../assets/images/greentick.png";
import loyalty from "../../assets/images/loyalty-img2.png";
import { Box, Button, FormControl, FormControlLabel, Modal, Radio, RadioGroup } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import close from "../../assets/images/close.png";
import GoggleReview from "../../assets/images/GoggleReview.png";
import goggle from "../../assets/images/GoggleG.png";
import termsCondation from '../../assets/images/termsCondation.png'
import { encode } from "base-64";

const style = {
  position: "fixed", // Change position to fixed
  bottom: 0, // Align modal to the bottom
  left: "50%",
  transform: "translateX(-50%)", // Center horizontally
  bgcolor: "background.paper",
  borderTopLeftRadius: "25px",
  borderTopRightRadius: "25px",
  maxHeight: "90vh", // Limit modal height to 90% of viewport height
  minHeight: "50vh",
  overflowY: "auto", // Add scroll when content overflows
};
const styleSurvey = {
  position: "fixed", // Change position to fixed
  border: 'none',
  bottom: 0,
  width: "100%",
  left: "50%",
  transform: "translateX(-50% )", // Center horizontally
  bgcolor: "background.paper",
  borderTopLeftRadius: "25px",
  borderTopRightRadius: "25px",
  minHeight: "60vh", // Limit modal height to 90% of viewport height
  overflowY: "auto", // Add scroll when content overflows
  outline: 'none',
};
const Order = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [randomSelecetedCampaing, setRandomSelecetedCampaing] = useState(0)
  const [hasCampaignDisplayed, setHasCampaignDispalyed] = useState(false);
  const { orderDetail, userDetail, getError } = useSelector((state) => state.user);
  const [mblTerms, setMblTerms] = useState(true);
  const [remainingTime, setRemainingTime] = useState("");
  const [isLoadingOnPageLoad, setIsLoadingOnPageLoad] = useState(false);
  // --------Modal----------------
  const [campaignModal, setCampaignModal] = React.useState(false);
  const [campaignYesModal, setCampaignYesModal] = React.useState(false);
  const [openModalSurvey, setOpenModalSurvey] = React.useState(false);
  const [surveyDataModal, setSurveyDataModal] = React.useState(false);
  const [feedbackModal, setFeedbackModal] = React.useState(false);
  const [openModalGoggleReview, setOpenModalGoggleReview] = React.useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [googleReviewLink, setGoogleReviewLink] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [otherFieldValue, setOtherFieldValue] = useState('');
  const [otherFieldVisible, setOtherFieldVisible] = useState(false);
  const [isOrderPickedUpClicked, setIsOrderPickedUpClicked] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [anonymous, setAnonymous] = useState(null);
  const reactionEmojies = ['😠', '😟', '😐', '🙂', '😀'];
  // useEffect(() => {
  //   if (getError && getError.length > 1) {
  //     toast.error(getError, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     dispatch(seTgetErrorNull())
  //   }
  // }, [getError])
  useEffect(() => {
    dispatch(getUser())
    dispatch(getBusinessOrderData(true, handleResponse))
  }, []);
  useEffect(() => {
    setInterval(() => {
      dispatch(getBusinessOrderData(false, handleResponse));
      setIsLoadingOnPageLoad(false);
    }, 5000);
  }, [dispatch]);

  useEffect(() => {
    console.log('userdetails updated ', userDetail)
    if (userDetail) {
      setIsLoadingOnPageLoad(false);
      // const startTime = moment(userDetail.start_time, "HH:mm:ss");
      const endTime = moment(userDetail.end_time, "HH:mm:ss");
      const currentTime = moment();

      if (currentTime.isBefore(endTime)) {
        const duration = moment.duration(endTime.diff(currentTime));
        const remainingTimeString = formatDuration(duration);
        setRemainingTime(remainingTimeString);
      } else {
        setRemainingTime("00:05:30");
      }
    }
  }, [userDetail]);

  const formatDuration = (duration) => {
    const hours = duration.hours().toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    const seconds = duration.seconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const UpdateUserOrderStatus = (orderId) => {
    setIsOrderPickedUpClicked(true);
    let data = {
      order_id: orderId,
      picked_up: 1,
      order_status: "Complete" //Complete,Notified,Complete and Notified,Cancel
    }
    dispatch(UpdateOrderStatus(data, handleUpdateOrderResponse))
  }

  const handleResponse = (status) => {
    if(!status){
      navigate('/account')
    }
  }

  const handleUpdateOrderResponse = (data) => {
    dispatch(getBusinessOrderData(true, handleResponse))
  }

  useEffect(() => {
    if (orderDetail) {
      setOrderTime({ startTime: orderDetail?.start_time, endTime: orderDetail?.end_time })
    }
    if (orderDetail?.is_notified == 1) {
      const timeoutId = setTimeout(() => {
        console.log(orderDetail.businessData.surveys + "+++++++++++++++++surverys")
        if (orderDetail?.businessData?.surveys?.length >= 1) {
          // setOpenModalSurvey(true);
        } else {
          // handleOpenFeedBack();
        }
        setGoogleReviewLink(orderDetail?.businessData?.settings?.google_review_link)
      }, 100);

      return () => clearTimeout(timeoutId);
    } else {
      if (orderDetail?.businessData?.campaign && !hasCampaignDisplayed) {
        setTimeout(() => {
          setCampaignModal(true);
          setHasCampaignDispalyed(true);
        }, 10000)
      }
    }
  }, [orderDetail]);

  useEffect(() => {
    if (orderDetail?.businessData?.b_campaigns?.length > 0) {
      setRandomSelecetedCampaing(Math.floor(Math.random() * orderDetail?.businessData?.b_campaigns?.length.length) || 0)
    }
  }, [orderDetail])

  const handleSubmitSurvey = (questionId) => {
    var user = JSON.parse(localStorage.getItem('userDetails'));
    var data;
    if (currentQuestionIndex < orderDetail?.businessData?.surveys?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      if (selectedOption.length != 0) {
        if (otherFieldVisible) {
          data = {
            option_number: selectedOption,
            option_value: otherFieldValue,
            question_id: questionId,
            user_id: user.id,
            business_id: orderDetail?.businessData?.id
          }
        } else {
          data = JSON.parse(selectedOption);
          data.question_id = questionId;
          data.user_id = user.id;
          data.business_id = orderDetail?.businessData?.id;
        }
        setSelectedOption('');
        setOtherFieldValue('');
      }

    } else {
      // If no more questions, close the modal
      if (selectedOption.length != 0) {
        if (otherFieldVisible) {
          data = {
            option_number: selectedOption,
            option_value: otherFieldValue,
            question_id: questionId,
            user_id: user.id,
            business_id: orderDetail?.businessData?.id
          }
        } else {
          data = JSON.parse(selectedOption);
          data.question_id = questionId;
          data.user_id = user.id;
          data.business_id = orderDetail?.businessData?.id;
        }
      }
      setSurveyDataModal(false);
      handleOpenFeedBack();
    }
    dispatch(submitSurvey(data, handleSubmitSurveyclose))
  };
  function handleSubmitSurveyclose(data) {
    toast.success('Great! Answer submited Successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  const handleSkipQuestion = () => {
    if (currentQuestionIndex < orderDetail?.businessData?.surveys?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);

    } else {
      // If no more questions, close the modal
      setSurveyDataModal(false);
      handleOpenFeedBack();
    }
  };
  function handleOpenFeedBack() {
    if (orderDetail?.businessData?.settings?.get_feedback != 1 || orderDetail?.hasProvidedFeedback) {
      handleOpenGoogleReview()
      return false;
    } else {
      setFeedbackModal(true);
    }
  }
  function handleOpenGoogleReview() {
    if (orderDetail?.businessData?.settings?.get_google_review != 1) {
      if (orderDetail?.is_loyalty) {
        navigate('/loyaltytwo', { replace: true })
      }
    } else {
      setOpenModalGoggleReview(true);
    }

  }

  function handleSubmitFeedback() {
    var user = JSON.parse(localStorage.getItem('userDetails'));
    if (selectedReaction == null || feedbackMessage.length < 0) {
      toast.error('Both reaction and message are required!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
    var data = {
      reaction: reactionEmojies[selectedReaction],
      message: feedbackMessage,
      order_id: orderDetail?.id,
      user_id: user.id,
      business_id: orderDetail?.businessData?.id,
      is_anonymous: anonymous ? 1 : 0
    }
    dispatch(submitFeedback(data, handleSubmitFeedbackclose))
  }

  function handleSubmitFeedbackclose(data) {
    toast.success('Great! Feedback is submited Successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setFeedbackModal(false); handleOpenGoogleReview();
  }

  function handleUpdateSurveyInteractionStatus(id, status) {
    var data = {
      survey_id: id,
      order_id: orderDetail?.id,
      interaction_status: status
    }
    dispatch(updateSurveyInteractionStatus(data))
  }

  function handleUpdateMarketingInteractionStatus(id, status) {
    setHasCampaignDispalyed(true);
    var data = {
      campaign_id: id,
      interaction_status: status,
      order_id: orderDetail?.id,
      business_id: orderDetail?.businessData?.campaign?.business_id,
      bk: encodeURIComponent(encode(orderDetail?.businessData?.id)),
    }
    dispatch(updateMarketingInteractionStatus(data))
  }

  //time calculate
  const [difference, setDifference] = useState(null);
  const [orderTime, setOrderTime] = useState(null);

  useEffect(() => {
    if (orderTime && orderTime.startTime) {


      const calculateDifference = () => {
        const startParts = orderTime.startTime.split(':').map(part => parseInt(part));
        const endParts = orderTime.endTime ? orderTime.endTime.split(':').map(part => parseInt(part)) : [new Date().getHours(), new Date().getMinutes(), new Date().getSeconds()];
        const start = new Date(0, 0, 0, startParts[0], startParts[1], startParts[2]);
        const end = new Date(0, 0, 0, endParts[0], endParts[1], endParts[2]);
        const diffInMilliseconds = end - start;
        const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);
        setDifference(`${hours}:${minutes}:${seconds}`);
      };

      calculateDifference();

      // Recalculate the difference every second if the end time is null
      const interval = setInterval(() => {
        if (!orderTime.endTime) {
          calculateDifference();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [orderTime]);



  return (
    <>
      <ToastContainer />
      <div className="container-fluid p-0">
        <img
          src={backgroundimg}
          className="feedback-Section w-100"
          alt=" Not Found"
        />

        {isLoadingOnPageLoad && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        <div className="container">
          <div className="d-flex justify-content-between align-items-center  mt-3">
            <div onClick={() => navigate("/account")}>
              <div style={{ cursor: 'pointer' }} className="  text-white leftText "><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5261_16697)">
                  <path d="M17.25 9.75C17.25 12.0375 15.975 14.0625 14.625 15.3V16.5C14.625 17.25 15 18.4125 18.75 19.125C19.8088 19.3276 20.7904 19.8207 21.5851 20.5492C22.3798 21.2776 22.9562 22.2128 23.25 23.25H0.75C0.922895 22.2187 1.41321 21.267 2.15263 20.5276C2.89204 19.7882 3.84371 19.2979 4.875 19.125C8.625 18.45 9.375 17.25 9.375 16.5V15.3C8.025 14.0625 6.75 12.075 6.75 9.75V6.15C6.75 2.25 9.525 0.75 12 0.75C14.475 0.75 17.25 2.25 17.25 6.15V9.75Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_5261_16697">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg></div>
            </div>
            <div>
              {" "}
              <img src={finalLogo} width={160} alt="" />
            </div>
            <div
              style={{ cursor: 'pointer' }}
              className="  text-white leftText"
              onClick={() => navigate("/questGame")}
            >
              play
            </div>
          </div>
          <div className="whitebg bg-white ">
            <div className="d-flex align-items-center justify-content-center">
              <img src={refre} alt="" className="" /> &nbsp;
              <h4 className="font-12-75 m-0">REFERENCE ID</h4>
            </div>
            {orderDetail && <h1 className="mb-0">{orderDetail.order_id}</h1>}
            <div className="borderdashed">
              <img src={circled} alt="w8" className="mb-0" />
            </div>
            <div className="graybg">
              <img src={orderDetail?.businessData?.image} alt="w8" />
            </div>
            <div className="order-detial mt-2">
              <h3 className="mb-1">
                {orderDetail?.businessData?.business_name}
              </h3>
              <p className="fw-medium mb-1">{orderDetail?.start_time}</p>
            </div>
            {orderDetail.is_loyalty == 0 ? (
              ""
            ) : (
              <div
                style={{ cursor: 'pointer' }}
                className="text-center d-flex justify-content-center lato"
                onClick={() => navigate("/offers")}
              >
                <div
                  className="  loyaltyText mt-2"
                  style={{ textTransform: "capitalize" }}
                >
                  <span>
                    <img src={loyalty} width={12} height={11} alt="w8" />
                  </span>
                  &nbsp;&nbsp;Loyalty
                </div>
              </div>
            )}
            <Stepper status={orderDetail?.status} notified={orderDetail?.is_notified} />
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 ps-4">
                  <div className="">
                    <div className=" d-flex   ">
                      <div>
                        <img src={location} alt="w8" />
                      </div>
                      <div>
                        <p className="text-darked fw-medium ms-2 mb-0">
                          <Link
                            target="_blank"
                            className="text-dark font-13-86 fw-medium"
                            to="https://www.google.com/maps/search/?api=1&query=-37.79947557778436,144.95669099090463"
                          >
                            {orderDetail?.businessData?.city}
                          </Link>
                        </p>
                        <p className="text-secondary fw-medium font-11 ms-2 fw-light m-0">
                          {orderDetail?.businessData?.business_name}
                        </p>
                      </div>
                    </div>
                    <div className=" d-flex align-items-center">
                      <div>
                        <img src={timer} alt="w8" />
                      </div>
                      <div>
                        <p className="mb-0 text-darked font-13-86 fw-medium ms-2">
                          {difference}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center d-flex justify-content-center">
            {
              !orderDetail?.status?.includes('Complete') && !isOrderPickedUpClicked &&
              <button
                disabled={orderDetail?.is_notified !== 1}
                className={`${orderDetail?.is_notified == 1 ? ' order-pickup-btn-order1' : 'order-pickup-btn-order '} 
              pt-3 pb-3 mt-2 mb-3`}
                style={orderDetail?.is_notified !== 1 ? { cursor: 'not-allowed' } : {}}
                onClick={() => UpdateUserOrderStatus(orderDetail?.id)}
              >
                Order picked up
              </button>
            }
          </div>
          {/* =================
          30 SECOND MODAL
          ================= */}
          <Modal
            open={openModalSurvey} className="d-flex align-items-center "
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock={true}
          >
            <Box className=" p-2 d-flex flex-column" sx={styleSurvey}>
              {/* <div className="text-end pe-3 pt-3">
                <span
                  role="button" onClick={() => { setOpenModalSurvey(false); setCampaignModal(true); }}
                  className=" inter fw-semibold text-gray font-20"
                >
                  <img src={close} width={24} height={24} alt="w8" />
                </span>

              </div> */}
              <div className="flex-grow-1 d-flex align-items-center px-3">
                <div className="w-100">

                  <div className=" p-3 pb-0 pt-2 position-relative">
                    <p
                      className="text-center mx-auto fw-light helvetica-thin " style={{ fontSize: "24px" }}
                    >
                      Got a moment?
                    </p>
                  </div>
                  <div className=" container">

                    <div className=" text-center mt-1 mb-3">
                      <p
                        className="font-15 helvetica-light"
                        style={{ color: "#636366" }}
                      >
                        Could you spare 30 seconds to take part in a short survey? Your answers help support your local businesses.
                      </p>

                    </div>
                    <br />
                    <div className="row justify-content-center ">
                      <div className="col-7">

                        <Button
                          type="button"
                          className="btn-fill-blue p-2 helvetica-medium fw-medium w-100 rounded-5  text-white mx-auto font-14-69 "
                          onClick={() => { setOpenModalSurvey(false); setSurveyDataModal(true) }}
                        >
                          Sure
                        </Button>
                      </div>
                    </div>
                    <div className="row justify-content-center ">

                      <div className="mt-3  col-7 text-center">

                        <Link
                          className="text-decoration-underline font-14- helvetica-light" style={{ color: "#636366" }}
                          onClick={() => { setOpenModalSurvey(false); handleOpenFeedBack() }}
                        >
                          Maybe later                          </Link>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </Box>
          </Modal>
          {/* =============
         Recommended modal
         ============= */}
          <Modal
            open={surveyDataModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock={true}
          >
            <Box className=" p-4 pb-0 px-2 w-100 " sx={style}>
              <div className="">
                <div className="pt-2 p-3  position-relative ">
                  <h6
                    className="Roobert-bold text-start  fw-bold font-24"
                    style={{ width: '90%' }}
                  >
                    {/* How likely are you to <br /> recommend us? */}
                    {orderDetail?.businessData?.surveys[currentQuestionIndex]?.question}
                  </h6>
                  {/* <Button
                    onClick={() => setSurveyDataModal(false)}
                    className="position-absolute top-0 end-0 poppins fw-semibold text-gray font-20"
                    variant="text"
                  >
                    <img src={close} width={24} height={24} alt="w8" />
                  </Button> */}
                </div>
                <div className="p-3 pb-0 pt-0 d-flex flex-column justify-content-between" style={{ height: '42vh' }}>
                  <FormControl>

                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                    >
                      {orderDetail?.businessData?.surveys?.length > 0 &&
                        orderDetail?.businessData?.surveys[currentQuestionIndex].options.map((index) => {
                          return (
                            index.is_text_field != 1 ?
                              <FormControlLabel value={`{"option_value":"${index.option_value}" , "option_number":"${index.option_number}"}`} onChange={(e) => { setOtherFieldVisible(false); setSelectedOption(e.target.value) }} control={<Radio style={{ color: '#3461FD' }} />} label={index.option_value} />
                              : <FormControlLabel value={index.option_number} onChange={(e) => { setOtherFieldVisible(true); setSelectedOption(e.target.value); }} control={<Radio style={{ color: '#3461FD' }} />} label={index.option_value} />
                          )
                        })}


                      {/* <FormControlLabel value="Very-likely" control={<Radio style={{ color: '#3461FD' }} />} label="Very likely" />
                      <FormControlLabel value="likely" control={<Radio style={{ color: '#3461FD' }} />} label="Likely" />
                      <FormControlLabel value="neutral" control={<Radio style={{ color: '#3461FD' }} />} label="Neutral" />
                      <FormControlLabel value="unlikely" control={<Radio style={{ color: '#3461FD' }} />} label="Unlikely" />
                      <FormControlLabel value="very-Unlikey" control={<Radio style={{ color: '#3461FD' }} />} label="Very Unlikey" />
                      <FormControlLabel value="not-sure" control={<Radio style={{ color: '#3461FD' }} />} label="Not sure" /> */}
                    </RadioGroup>
                    {otherFieldVisible && <input className="my-2 rounded p-2 border" style={{ borderColor: '#D9D9D9', height: 65, outline: 2, outlineColor: '#3867FA' }} value={otherFieldValue} onChange={e => setOtherFieldValue(e.target.value)} />}
                  </FormControl>
                  <div className=" row justify-content-center">
                    <div className="col-6">
                      <Button
                        type="button"
                        className="btn-border p-3 py-2 rounded-3  w-100 text-white mx-auto font-14-69 montserrat"
                        onClick={() => { handleSkipQuestion(); handleUpdateSurveyInteractionStatus(orderDetail?.businessData?.surveys[currentQuestionIndex].id, 'skipped') }}
                      >
                        Skip
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        type="button"
                        className="btn-fill-blue p-3 py-2 rounded-3 w-100 mb-3 text-white mx-auto font-14-69 montserrat"
                        onClick={() => { handleSubmitSurvey(orderDetail?.businessData?.surveys[currentQuestionIndex].id); handleUpdateSurveyInteractionStatus(orderDetail?.businessData?.surveys[currentQuestionIndex].id, 'completed') }}
                      >
                        Submit
                      </Button>

                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>

          {/* =============
          Order Complete
          =========== */}

          <Modal
            open={campaignModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock={true}
          >
            <Box className=" p-4 px-2 w-100 " sx={style}>
              {mblTerms ? <div className="">
                <div className="pt-0 p-3 position-relative ">
                  <h6
                    className="inter text-center mx-auto fw-semibold font-15"
                    id="exampleModalLabel"
                  >
                    Interesting Offer
                  </h6>
                  {/* <Button
                    onClick={() => { setCampaignModal(false); handleOpenFeedBack() }}

                    className="position-absolute top-0 end-0 poppins fw-semibold text-gray font-20"
                    variant="text"
                  >
                    <img src={close} width={24} height={24} alt="w8" />

                  </Button> */}
                </div>
                <div className="p-3 pt-0 container">
                  <div className="row justify-content-center">
                    <div className="col-9 rounded-2">
                      <img src={orderDetail?.businessData?.campaign?.offer_images} className="w-100 rounded-2" alt="" />
                    </div>
                  </div>
                  <div className="text-start px-4 mt-3 mb-3">
                    <h6 className="font-13 fw-semibold inter">
                      {orderDetail?.businessData?.campaign?.offer_heading}
                    </h6>
                    <p
                      className="font-13 fw-medium inter"
                      style={{ color: "#5B5B5B" }}
                    >
                      {orderDetail?.businessData?.campaign?.offer_subheading}<u role='button'
                        onClick={() => setMblTerms(false)}> Terms & Conditions</u> apply.

                      Details will be applied to account {userDetail?.phone}.
                    </p>
                  </div>
                  <div className="container p011t-3">
                    <div className="row justify-content-center">
                      <div className="col-9">
                        {/* <Button
                          type="button"
                          className="btn-fill-blue p-3 rounded-3 w-100 mb-3 text-white mx-auto font-14-69 montserrat"
                          onClick={() => { setCampaignYesModal(true); setCampaignModal(false) }}
                        >
                          Yes Please
                        </Button>
                        <Button
                          type="button"
                          className="btn-fill-gray p-3 rounded-3  w-100 text-white mx-auto font-14-69 montserrat"
                          onClick={() => { handleOpenFeedBack(); setCampaignModal(false) }}
                        >
                          No Thanks
                        </Button> */}
                        <div className="row justify-content-center mt-2">
                          <div className="col-9">

                            <Button
                              type="button"
                              className="btn-fill-blue p-2 helvetica-medium fw-medium w-100 rounded-5  text-white mx-auto font-14-69 "
                              onClick={() => { setCampaignYesModal(true); setCampaignModal(false); handleUpdateMarketingInteractionStatus(orderDetail?.businessData?.campaign?.id, 'completed') }}
                            >
                              Sure
                            </Button>
                          </div>
                        </div>
                        <div className="row justify-content-center ">

                          <div className="mt-3  col-7 text-center">

                            <Link
                              className="text-decoration-underline font-14- helvetica-light" style={{ color: "#636366" }}
                              onClick={() => { handleOpenFeedBack(); setCampaignModal(false); handleUpdateMarketingInteractionStatus(orderDetail?.businessData?.campaign?.id, 'skipped') }}
                            >
                              Maybe later                          </Link>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                :
                <div className="p-2 px-3 pb-5">
                  <div className="d-flex justify-content-between align-items-start   ">
                    <div>
                      <h4 className='fw-bold mb-0 inter'>Offer Conditions</h4>
                      <p
                        className="inter text-center text-dark-gray mb-1 font-14" style={{ color: '#898989' }}
                      >
                        Terms & Conditions apply.
                      </p>
                    </div>

                    <img role='button' src={termsCondation} onClick={() => setMblTerms(true)} alt="w8" width={25} />


                  </div>
                  <p
                    className="font-14 pb-2 mt-2 fw-medium inter"
                    style={{ color: "#535353", wordBreak: "break-all" }}
                  >
                    {orderDetail?.businessData?.campaign?.nitty_gritty_stuff}
                  </p>
                </div>}
            </Box>
          </Modal>

          {/* Marketing yes model  */}
          <Modal
            open={campaignYesModal} className="d-flex align-items-center "
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock={true}
          >
            <Box className=" p-2 d-flex flex-column" sx={styleSurvey}>
              {/* <div className="text-end pe-3 pt-3">
                <span
                  role="button" onClick={() => { setCampaignYesModal(false); handleOpenFeedBack(); }}
                  className=" inter fw-semibold text-gray font-20"
                >
                  <img src={close} width={24} height={24} alt="w8" />
                </span>

              </div> */}
              <div className="flex-grow-1 d-flex align-items-center px-3">
                <div className="w-100">

                  <div className=" p-3 pb-1 pt-2 position-relative d-flex flex-column justify-content-center align-items-center">
                    <img className="my-3" src={greentick} width={30} height={30} alt="w8" />
                    <p
                      className="text-center mx-auto fw-light helvetica-thin " style={{ fontSize: "24px" }}
                    >
                      Thanks for signing up!
                    </p>
                  </div>
                  <div className=" container">

                    <div className=" text-center mt-1 mb-3">
                      <p
                        className="font-15 helvetica-light p-1"
                        style={{ color: "#636366" }}
                      >
                        You will receive an SMS with your exclusive offer and how to redeem within 24 Hours. Your offer can also be found within your setting page under the ‘Offers & Loyalty’ section.
                      </p>

                    </div>
                    <br />
                    <div className="row justify-content-center ">
                      <div className="col-9">

                        <Button
                          type="button"
                          className="btn-fill-black p-2 helvetica-medium fw-medium w-100 rounded-5  text-white mx-auto font-14-69 "
                          onClick={() => { setCampaignYesModal(false) }}
                          style={{ backgroundColor: 'black' }}
                        >
                          Finish
                        </Button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </Box>
          </Modal>
          {/* =========

          {/* =============
        Hey there MODAL
         ============= */}
          <Modal
            open={feedbackModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock={true}
          >
            <Box className=" p-4 pb-0 px-2 w-100 " sx={style}>
              <div className="">
                <div className="pt-2 p-3 pb-1 position-relative ">
                  <h6
                    className="inter text-start  fw-bold font-24"
                  >
                    Hey there!
                  </h6>
                  <p
                    className="font-14  inter"
                    style={{ color: "#898989" }}
                  >Leave us feedback so we know how we’re doing?</p>
                  {/* <Button
                    onClick={() => { setFeedbackModal(false); handleOpenGoogleReview(); }}
                    className="position-absolute top-0 end-0 poppins fw-semibold text-gray font-20"
                    variant="text"
                  >
                    <img src={close} width={24} height={24} alt="w8" />
                  </Button> */}
                </div>

                <div className="p-3 pb-0 pt-0 container">

                  <div className="hyModal-textare">
                    <div className="d-flex flex-row justify-content-between">
                      {
                        reactionEmojies.map((value, index) => {
                          return (
                            <span onClick={() => { setSelectedReaction(index) }} style={selectedReaction == index ? { backgroundColor: '#3461FD' } : {}}>{value}</span>
                          )
                        })
                      }
                    </div>
                    <textarea
                      rows="7"
                      className="form-control mt-4 font-14 "
                      placeholder="Tell us what you think..."
                      value={feedbackMessage}
                      onChange={(e) => { setFeedbackMessage(e.target.value) }}
                    ></textarea>
                    <p
                      className="font-12 mt-3 inter text-start"
                      style={{ color: "#D1D5DB" }}
                    >
                      Your input, our improvement, your privacy.
                    </p>
                  </div>
                  <div className="d-flex flex-row align-content-center gap-2">
                    <input type="checkbox" checked={anonymous} onChange={() => { setAnonymous(!anonymous) }} />
                    <p
                      className="font-12 mt-3 inter text-start"
                      style={{ color: "#000" }}
                    >
                      Submit my feedback anonymously.
                    </p>
                  </div>
                  <div className="mt-3 row justify-content-center">
                    <div className="col-6">
                      <Button
                        type="button"
                        className="btn-border p-3 py-2 rounded-3  w-100 text-white mx-auto font-14-69 montserrat"
                        onClick={() => { setFeedbackModal(false); handleOpenGoogleReview(); }}
                      >
                        Skip
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        type="button"
                        className="btn-fill-blue p-3 py-2 rounded-3 w-100 mb-3 text-white mx-auto font-14-69 montserrat"
                        onClick={() => { handleSubmitFeedback() }}
                      >
                        Submit
                      </Button>

                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
          {/* =============
        Hey there MODAL
         ============= */}
          <Modal
            open={openModalGoggleReview}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock={true}
          >
            <Box className=" p-4 pb-0 px-2 w-100 " sx={style}>
              <div className="">
                <div className="pt-2 p-3 pb-1  text-center">
                  <h5 className="inter fw-semibold"
                  >
                    Thank you for that!
                  </h5>
                  {/* <Button
                    onClick={() => { setOpenModalGoggleReview(false); if (orderDetail?.is_loyalty) { navigate('/loyaltytwo') } }}
                    className="position-absolute top-0 end-0 poppins fw-semibold text-gray font-20"
                    variant="text"
                  >
                    <img src={close} width={24} height={24} alt="w8" />
                  </Button> */}
                </div>

                <div className="p-3 pb-0 pt-0 container ">
                  <div className="row justify-content-center ">
                    <div className="col-8 my-4">

                      <img src={GoggleReview} className="w-100" alt="w8" />

                    </div>
                    <p
                      className="font-13 mb-4 text-center inter"
                      style={{ color: "#5B5B5B" }}
                    >Your feedback goes a long way! How about <br /> a google review?</p>
                  </div>

                  <div className="mt-3 row justify-content-center mb-4">
                    <div className="col-12">
                      <a
                        href={googleReviewLink} target="_blank"
                        rel="noreferrer"
                      >

                        <Button
                          type="button"
                          className="btn-border px-2 py-2 rounded-3 justify-content-between w-100 align-items-center text-white mx-auto font-14-69 montserrat"

                        >
                          <img src={goggle} alt="w8" width={50} height={50} className="h-100 px-1" />
                          <p className="mb-0 font-14 inter" >Google Review</p>
                          <img src={goggle} alt="w8" width={50} height={50} className="px-1" style={{ visibility: 'hidden' }} />

                        </Button>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default Order;
