import React from "react";
import backgroundimg from "../../assets/images/headerscren.png";
import finalLogo from "../../assets/images/finalLogo.png";
import "../../assets/css/loyalty.scss";

import qrcode from "../../assets/images/qr.png";
import { Button } from "@mui/material";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";

const QrCodeMarketing = () => {
  const navigate = useNavigate();
  const { orderDetail, userDetail } = useSelector((state) => state.user);
  var user = JSON.parse(localStorage.getItem('userDetails'));
  const handelback = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="container-fluid p-0">
        <img
          src={backgroundimg}
          className="feedback-Section w-100"
          alt=" Not Found"
        />
        <div className="container d-flex justify-content-between align-items-center  my-4">
          <button className="back_button" onClick={handelback}>
            {<IoChevronBackOutline />}
          </button>

          <img src={finalLogo} width={160} alt="w8" onClick={() => navigate("/order")} />

          <div
            className=" inter font-12 text-white leftText mt-2"
            style={{ textTransform: "capitalize" }} onClick={() => navigate("/setting")}
          >
            ***807
          </div>
        </div>

        <div className="container pb-3">
          <div className=" qrTEXT bg-white pt-4 pb-4 rounded-3 overflow-hidden"
          >
            <div>
              <div className="d-flex align-items-center mt-2 justify-content-center">
                <h6
                  className="lato font-18 fw-bold mb-0 pe-2"
                  style={{ color: "#2F3542" }}
                >
                  {orderDetail?.businessData?.business_name}{" "}
                </h6>
                <p
                  className="font-14 mb-0 lato ps-2  fw-bold "
                  style={{
                    color: "#2F3542",
                    borderLeft: "1.5px solid #2F3542",
                  }}
                >
                  {" "}
                  {orderDetail?.loyalty?.description}
                </p>
              </div>
              <div className="container mt-4">
                <div className="row justify-content-center">
                  <div className="col-7 text-center">
                    <p
                      className="font-15 mb-0 montserrat fw-medium lh-1"
                      style={{
                        color: "#262626",
                      }}
                    >
                      {" "}
                      Show your QR code or provide your unique code to redeem.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="position-relative qr-code-circle">
              <span className="ok"></span>
              <span className="notok"></span>
            </div>
            <div className="container mt-4">
              <div className="qr text-center m-auto">
                {/* <img
                      src={qrcode}
                      alt=""
                      className="mt-2"
                      height={150}
                      width={150}
                    /> */}
                <QRCode
                  size={150}
                  value={JSON.stringify({ "user_id" : user.id , "loyalty_id": orderDetail?.loyalty?.id})}
                />
              </div>
            </div>
            <div className="container">
              <div className="row mt-4 QR-Code justify-content-center">
                <div className="col-8 text-center rounded-2">
                  <h4 className="font-25 inter fw-semibold m-0"> {orderDetail?.loyalty?.id}</h4>
                </div>
                <div className="text-center">
                  <Button variant="outlined" onClick={() => navigate('/order')} className="w-auto rounded-4 mt-3 qr-button">
                    Text me a link
                  </Button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* react model */}
    </>
  );
};

export default QrCodeMarketing;
