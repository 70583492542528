import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "reactstrap";
import { useEffect } from "react";

function ReactModel({modal, setModal}) {
  // Modal open state
  // Effect to show the modal after five seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setModal(true);
    }, 1000);

    // Clear the timer if the component unmounts before it triggers
    return () => clearTimeout(timer);
  }, []);
  // Toggle for Modal
  const toggle = () => setModal(!modal);

  return (
    <div
      style={{
        display: "block",
        width: 700,
        padding: 30,
      }}
    >
      <Modal
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 1000 }}
        className="modal-dialog-centered"
      >
        <div class="rounded1  pb-3 " style={{backgroundColor:'black'}}>
          <div class="d-flex p-3  align-items-center">
            <h1 class="text-center font-25 inter mx-auto text-white fw-bold" id="exampleModalLabel">
              LinkQuest
            </h1>
            <button
              type="button"
              class=" justify-content-end bg-transparent text-white"
              onClick={toggle}
            >
              X
            </button>
          </div>

          <div className="d-flex justify-content-start flex-column ps-3 pe-3">
            <h4 className="text-white font-25 inter fw-bold">How to Play</h4>
            <h6 className="m-0 font-15 text-white inter">Play while you wait.</h6>
            <p className="p-0 font-12 text-white inter">You only have 6 tries</p>
          </div>
          <div className="ps-2 pe-3 spanTgas inter">
            <div class=" ">
              <span className="bg-success">H</span>
              <span>O</span>
              <span>U</span>
              <span>R</span>
              <span>S</span>
              <p className="mt-1 ms-1 font-12 text-white">
                H is in the Correct Word In The Correct Spot
              </p>
            </div>
            <div class=" mt-3">
              <span>U</span>
              <span>N</span>
              <span className="lin2active">I</span>
              <span>T</span>
              <span>E</span>
              <p className="mt-1  ms-1 font-12 text-white">
                I is in the word and but is in the incorrect spot.
              </p>
            </div>
            <div class="">
              <span>T</span>
              <span>A</span>
              <span>L</span>
              <span>L</span>
              <span className="line3active">Y</span>
              <p className="mt-1  ms-1 font-12 text-white">
                Y is not in the word in any spot.
              </p>
            </div>
          </div>
            <div className="mx-auto text-center ">
              <button
              type="button"
                className="okbutton mt-4"
                onClick={toggle}
              >
                OKAY
              </button>
            </div>
        </div>
      </Modal>
    </div>
  );
}

export default ReactModel;
