import React from "react";
import "../assets/css/versionThree.scss";
import img from "../assets/images/cafe.png";
import { useLocation, useNavigate } from "react-router-dom";
import { IoClose, IoSearch } from "react-icons/io5";
import { MdOutlineMyLocation } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import CoverReward from ".././assets/images/CoverReward.png";
import { Box, Modal } from "@mui/material";
import useAOS from "../customHook/useAOS";
import QRCode from "react-qr-code";
import { FaStar } from "react-icons/fa";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "white",
  // border: '2px solid #000',
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
const RewardDetail = () => {
  const location = useLocation();
  const { reward, businessData } = location?.state || "";
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  const handleHome = () => {
    navigate("/account");
    setModal(false);
  };
  const [viewEVoucher, setViewEVoucher] = React.useState(false);
  var user = JSON.parse(localStorage.getItem("userDetails"));

  // const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  // const headerHeight = 59; // Assume header height is 56px

  // React.useEffect(() => {
  //     function handleResize() {
  //         setScreenHeight(window.innerHeight);
  //     }

  //     window?.addEventListener('resize', handleResize);
  //     return () => window.removeEventListener('resize', handleResize);
  // }, []);
  useAOS();

  return (
    <>
      <div style={{ height: "59px" }}>
        <div
          data-aos="fade-left"
          data-aos-duration="500"
          className="position-fixed w-100 top-0 bg-white z-3"
          style={{
            height: "59px",
            border: "1.5px solid #CACACA",
          }}
        >
          <div className=" p-3 ">
            <p className="font-15 fw-semibold mb-0 d-flex align-items-center ">
              <IoClose size={18} onClick={() => navigate(-1)} />
              &nbsp;{businessData?.business_name} - Reward Details
            </p>
          </div>
        </div>
      </div>
      <section
        data-aos="fade-left"
        data-aos-duration="500"
        className=" z-1 position-relative "
      >
        <div className="contianer-fluid p-0  w-[85%] mx-auto">
          <div>
            {/* ========= banner ======= */}
            <div className=" mt-4">
              {/* <h5 className='text-white'>{reward?.type == 'free_item' ? reward?.item_name + ' |' : reward?.discount_percentage ? reward?.discount_percentage + '%' : reward?.discount_amount + '$'}{' '}{reward?.type?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h5> */}
              <img
                src={CoverReward}
                className="w-full h-40 object-cover rounded-3xl shadow-md"
                alt=""
              />
            </div>
            {/* ========= 25% discount voucher  */}
            <div className=" manrope mt-4  offer-detail">
              <h5 className="text-xl font-semibold">25% discount voucher</h5>
              <div className=" flex w-fit items-center text-xs font-medium gap-1 bg-black text-white p-2 px-2.5 rounded-full">
                <span className="p-[.12px] w-4 h-4 flex items-center justify-center border-2 border-white rounded-full">
                  <FaStar size={10} />
                </span>
                400
              </div>
              <p className="text-sm text-gray-600 mt-2">For all members</p>
              {/* <h5 className="fw-bolf fw-bold mb-2">
                {reward?.type == "free_item"
                  ? reward?.item_name + " |"
                  : reward?.discount_percentage
                  ? reward?.discount_percentage + "%"
                  : reward?.discount_amount + "$"}{" "}
                {reward?.type
                  ?.split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </h5>
              <div
                className="progress rounded-2 "
                style={{ width: "85%", height: "14px" }}
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow={0}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div className="progress-bar" style={{ width: "0%" }} />
              </div>
              <p className="fw-bolf fw-medium font-12 mb-0 text-black-50 mt-2">
                100 points{" "}
              </p> */}

              <svg
                width="100%"
                className="mt-3"
                height="6"
                viewBox="0 0 393 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="393"
                  height="6"
                  fill="url(#paint0_linear_5559_12520)"
                />
                <rect width="390" height="0.5" fill="#E1E1E1" />
                <defs>
                  <linearGradient
                    id="paint0_linear_5559_12520"
                    x1="196.5"
                    y1="0"
                    x2="196.5"
                    y2="6"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#F2F4F7" />
                    <stop offset="1" stop-color="#F2F4F7" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="mt-2">
                <p className="text-lg font-medium mb-2">About this Discount</p>
                <p className="text-sm">
                  Sure thing! Your "25% discount voucher" to use at “” is a
                  great offer that allows you to enjoy a quarter off the regular
                  price on selected items or services. This voucher is your
                  ticket to some sweet savings—whether you're treating yourself
                  or snagging a great deal for someone special.{" "}
                </p>
                <p className="text-sm">
                This voucher is your ticket to some sweet savings—whether you're treating yourself or snagging a great deal for someone special. 
                </p>
                <p className="text-lg font-medium mb-2">Terms & Conditions</p>
                <p className="text-sm">
                Sure thing! Your "25% discount voucher" to use at “” is a great offer that allows you to enjoy a quarter 
                </p>
              </div>
            </div>
          </div>
          {/* <div className=" flex-grow-1 d-flex flex-column">
            <div className="container-fluid mt-2">
              <ul className=" row nav nav-pills " id="pills-tab" role="tablist">
                <li className="nav-item col-6 p-0" role="presentation">
                  <button
                    className="nav-link fw-medium manrope pb-3 pt-2 rounded-0 w-100 active bg-transparent font-14"
                    id="pills-details-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-details"
                    type="button"
                    role="tab"
                    aria-controls="pills-details"
                    aria-selected="true"
                  >
                    Details
                  </button>
                </li>
                <li className="nav-item col-6 p-0" role="presentation">
                  <button
                    className="nav-link rounded-0 manrope pb-3 pt-2 fw-medium w-100 bg-transparent font-14"
                    id="pills-terms-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-terms"
                    type="button"
                    role="tab"
                    aria-controls="pills-terms"
                    aria-selected="false"
                  >
                    Terms & Conditions
                  </button>
                </li>
              </ul>
            </div>
            <div
              className="tab-content manrope p-3 pt-0 h-100 "
              id="pills-tabContent"
            >
              <div
                className="tab-pane fade show h-100 active"
                id="pills-details"
                role="tabpanel"
                aria-labelledby="pills-details-tab"
                tabIndex={0}
              >
                <div className=" h-100 justify-content-between d-flex flex-column">
                  <div className="mt-3">
                    <p className="font-14 text-black-50">
                      Sure thing! Your "25% discount voucher" to use at “” is a
                      great offer that allows you to enjoy a quarter off the
                      regular price on selected items or services.{" "}
                    </p>
                    <p className="font-14 text-black-50">
                      This voucher is your ticket to some sweet savings—whether
                      you're treating yourself or snagging a great deal for
                      someone special.{" "}
                    </p>
                  </div>
                  <button
                    onClick={() => setModal(true)}
                    className="btn-fill-blue p-2 rounded-5 fw-semibold"
                    type="button"
                  >
                    Redeem now
                  </button>
                </div>
              </div>
              <div
                className="tab-pane fade h-100  "
                id="pills-terms"
                role="tabpanel"
                aria-labelledby="pills-terms-tab"
                tabIndex={0}
              >
                <div className=" h-100 justify-content-between d-flex flex-column">
                  <div className="mt-3">
                    <p className="font-14 text-black-50">
                      Sure thing! Your "25% discount voucher" to use at “” is a
                      great offer that allows you to enjoy a quarter off the
                      regular price on selected items or services.{" "}
                    </p>
                    <p className="font-14 text-black-50">
                      This voucher is your ticket to some sweet savings—whether
                      you're treating yourself or snagging a great deal for
                      someone special.{" "}
                    </p>
                  </div>
                  <button
                    onClick={() => setModal(true)}
                    className="btn-fill-blue p-2 rounded-5 fw-semibold"
                    type="button"
                  >
                    Redeem now
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* ============ redeem modal ========== */}
      {/* <Modal
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="lato text-center ">
          {viewEVoucher ? (
            <QRCode
              className="my-5"
              size={160}
              value={JSON.stringify({
                user_id: user.id,
                reward_id: reward?.id,
              })}
            />
          ) : (
            <>
              <div className="flex justify-center">
                <svg
                  fill="#3461FD"
                  width="85px"
                  height="85px"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>ionicons-v5-e</title>
                  <path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48ZM364.25,186.29l-134.4,160a16,16,0,0,1-12,5.71h-.27a16,16,0,0,1-11.89-5.3l-57.6-64a16,16,0,1,1,23.78-21.4l45.29,50.32L339.75,165.71a16,16,0,0,1,24.5,20.58Z" />
                </svg>
              </div>
              <h4 className="fw-bold mt-3" style={{ color: "#2F3542" }}>
                Congrats! <br />
                Your E-voucher is Ready!
              </h4>
              <p className="font-14 mt-3" style={{ color: "#2F3542" }}>
                Time to treat yourself! show this e-voucher at the store to
                redeem your reward.
              </p>
            </>
          )}
          <br />
          <div className="flex-column d-flex gap-3 w-75 mx-auto ">
            {!viewEVoucher && (
              <button
                className="helvetica-medium fw-medium  btn-fill-blue p-3 rounded-5 fw-semibold"
                type="button"
                onClick={() => {
                  setViewEVoucher(true);
                }}
              >
                View E-Voucher
              </button>
            )}
            <button
              className="helvetica-medium fw-medium  p-3 rounded-5 fw-semibold text-blue"
              type="button"
              style={{ background: "#CCD7FF" }}
              onClick={handleHome}
            >
              Go Home
            </button>
          </div>
        </Box>
      </Modal> */}
    </>
  );
};

export default RewardDetail;
