import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './assets/css/style.css';
import { getUser } from './redux/slice/UserSlice';
import AppRoute from './routes';
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUser())
  }, [])
  return (
    <>
      <BrowserRouter>
        <AppRoute />
      </BrowserRouter>

    </>
  )
}

export default App
