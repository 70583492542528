import { createSlice } from '@reduxjs/toolkit'
import { dispatch } from '../store/store'
import axios from '../../utils/axiosBaseUrl';

const initialState = {
  isLoading: false,
  getErrorOrder: null,
  userList: [],
  userDetail: {},
}

const updateOrderSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    //START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    //STOP LOADING
    stopLoading(state) {
      state.isLoading = false
    },
  
    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false
      state.getErrorOrder = action.payload
    },

    // GET User Success
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload
    },

    // GET User DETAILS
    getUserDetailsSuccess(state, action) {
      state.isLoading = false;
      state.userDetail = action.payload
    },
  },
})
export const { getUserDetailsSuccess } = updateOrderSlice.actions;
export default updateOrderSlice.reducer

// User Functions

//User Login
export function UpdateOrderStatus(data, handleClose) {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')

    dispatch(updateOrderSlice.actions.startLoading());
    try {
      const response = await axios.post('business/order/status',
        data, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken

          }
      });
      handleClose(response.data);
      if (!response.data.status) {
        dispatch(updateOrderSlice.actions.hasGetError(response?.data?.message));
      }
      console.log(JSON.stringify(response?.data?.data))
      dispatch(updateOrderSlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error);
      dispatch(updateOrderSlice.actions.hasGetError(error?.message));
    }
  };
}