import React from 'react'
import { IoClose } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom';
import OrderCard from '../components/OrderCard';
import '../assets/css/versionThree.scss'
import img from '../assets/images/cafe.png'
import useAOS from '../customHook/useAOS';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserOrders, seTgetErrorNull } from '../redux/slice/UserSlice'
import { useEffect } from 'react';
import { Spinner } from 'reactstrap';
import moment from 'moment';

const OrderHistry = () => {
    const navigate = useNavigate();
    useAOS();

    const dispatch = useDispatch();
    const { isLoading, getError, userOrders } = useSelector((state) => state.user);
    const token = localStorage.getItem('accessToken');
    if (!token) {
        navigate('/orderHistry/withOut-login')
    }
    // useEffect(() => {
    //     if (getError && getError.length > 1) {
    //         toast.error(getError, {
    //             position: "top-right",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "light",
    //         });
    //         dispatch(seTgetErrorNull())
    //     }
    // }, [getError])
    useEffect(() => {
        dispatch(getUserOrders())
    }, [])
    return (
        <>
            <div data-aos="fade-left" data-aos-duration="500" className='position-fixed w-100 top-0 bg-white z-3' style={{
                height: "59px",
                border: "1.5px solid #CACACA"
            }}>
                <div className=' p-3 '>
                    <p className='font-15 fw-semibold mb-0  flex items-center'><IoClose size={18} onClick={() => navigate(-1)} />&nbsp;Order history</p>
                </div>


            </div>

            <section data-aos="fade-left" data-aos-duration="500" className='p-3 z-1 position-relative ' style={{ marginTop: "59px" }}>
                {
                    isLoading ?
                        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                            <Spinner color='black' size={'md'} />
                        </div>
                        :
                        userOrders?.length > 0 ?
                        userOrders?.map((order) => {
                            return (
                                <OrderCard img={order?.business?.image} head={order?.business?.business_name} ref_id={"Reference ID: "+order?.order_id} date={moment(order?.created_at).format('ddd, MMM D')} time={moment(order?.created_at).format('h:mm A')} loyaltyPoints={order?.points_earned} status={order?.status} />
                            )
                        }):<div className='text-center'><p>No Recent Orders</p></div>
                }
            </section>
            <ToastContainer />
        </>
    )
}

export default OrderHistry
