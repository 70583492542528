import React, { useState, useEffect, useRef } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input'
import { TextField } from '@mui/material';
import useAOS from '../customHook/useAOS';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileSetting } from '../redux/slice/UserSlice';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from 'reactstrap';

const ManageDetails = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('accessToken');
    useAOS();
    const dispatch = useDispatch();
    const { isLoading, userDetail, getError } = useSelector((state) => state.user);
    if (!token) {
        navigate('/orderHistry/withOut-login')
    }
    useEffect(()=>{
        if (!token) {
            navigate('/orderHistry/withOut-login')
        }
    },[])
    // console.log(number, 'number')
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const headerHeight = 59; // Assume header height is 56px
    const [number, setNumber] = useState()
    const [isPhoneDisabled, setIsPhoneDisabled] = useState(false)
    const [name, setName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    useEffect(() => {
        if (userDetail) {
            setNumber(userDetail?.phone)
            setName(userDetail?.firstname)
            setLastName(userDetail?.lastname)
            setEmail(userDetail?.email)
        }
    }, [userDetail])
    // useEffect(() => {
    //     if (getError && getError.length > 1) {
    //         toast.error(getError, {
    //             position: "top-right",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "light",
    //         });
    //         dispatch(seTgetErrorNull())
    //     }
    // }, [getError])
    useEffect(() => {
        function handleResize() {
            setScreenHeight(window.innerHeight);
        }

        window?.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => { setNumber(userDetail.phone) }, [])

    const handleUpdateDetails = () => {
        let data = {}
        if (name && name.length > 0) {
            data.firstname = name
        }
        if (name && name.length > 0) {
            data.lastName = lastName
        }
        if (email && email.length > 0) {
            if (email !== userDetail.email) {
                data.email = email
            }
        }
        dispatch(updateProfileSetting(data, function () {
            toast.success('Details updated successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }))
    }
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);
    useEffect(() => {
        if (number) {
            setIsPhoneDisabled(true)
        }
    }, [number]);
    return (
        <>
            <div data-aos="fade-left" data-aos-duration="500"
                className='position-fixed w-100 top-0 bg-white z-3 manrope' style={{
                    height: "59px",
                    border: "1.5px solid #CACACA"
                }}>
                <div className=' p-3 d-flex align-items-center'>
                    <p className='font-15 fw-semibold mb-0  flex items-center'><FaArrowLeft size={18} onClick={() => navigate(-1)} />&nbsp;Manage details</p>
                </div>


            </div>
            <form data-aos="fade-left" data-aos-duration="500" className='manage-detail-main z-1 position-relative manrope d-flex p-3 flex-column justify-content-between  ' style={{ minHeight: `calc(${screenHeight}px - ${headerHeight}px)`, marginTop: "59px" }}>
                <div className='manag-detail mt-3 '>
                    <h3 className='fw-bold'>Manage details</h3>
                    <p className='font-13'>Adjust your timelink account details</p>
                    <div className='animated-field rounded-3 p-1 mb-3'>

                        <TextField focused={userDetail?.firstname ? true : false} id="outlined-basic" required className="w-100" type='text' label="First name" variant="filled" value={name} onChange={(e) => { setName(e.target.value) }} />
                    </div>
                    <div className='animated-field rounded-3 p-1 mb-3'>

                        <TextField focused={userDetail?.lastname ? true : false} id="outlined-basic" required className="w-100" type='text' label="Last name" variant="filled" value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                    </div>
                    <div className='animated-field rounded-3 p-1 mb-3'>

                        <TextField focused={userDetail?.email ? true : false} id="outlined-basic" required className='w-100' type='email' label="Email" variant="filled" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                    </div>


                    <p className='font-13 text-black-50'>Add your email to get special updates.</p>
                    {
                        number &&

                        <div className=' d-flex  '>
                            <div className='new-phone-number-field'>

                                <PhoneInput
                                    className="fw-medium Roobert-medium h-100 "
                                    international
                                    defaultCountry="AU"
                                    value={number}
                                    placeholder="Phone Number"
                                    onChange={setNumber}
                                    readOnly={true}
                                // onFocus={() => { setPhoneBoxActive(true) }}
                                // onBlur={() => { setPhoneBoxActive(false) }}
                                />
                            </div>
                            <div className=" animated-field rounded-3 p-1 pe-2 w-100 d-flex align-items-center">
                                <div className='w-100'>

                                    <TextField id="outlined-basic" required className='w-100' type='text'
                                        // ref={inputRef}
                                        // focused={true}
                                        // autoFocus={!!number}
                                        value={number} onChange={(e) => setNumber(e.target.value)}
                                        label="Phone Number" variant="filled" InputLabelProps={{
                                            sx: {
                                                transform: number ? 'translate(1, -0.1rem) scale(0.75)' : 'translate(0, 1rem)',
                                            },
                                            className: `${number?.length > 0 ? "css-o943dk-MuiFormLabel-root-MuiInputLabel-root font-18" : ""}`,
                                        }}
                                        disabled={isPhoneDisabled}
                                    />

                                </div>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5267_41567)">
                                        <path d="M11.5124 0.905867C11.9242 0.488734 12.6187 0.488734 13.0305 0.905867L15.0314 2.93252C15.2752 3.17966 15.6343 3.29106 15.9838 3.2281L18.8496 2.71176C19.4395 2.6055 20.0012 2.99539 20.0778 3.56406L20.4492 6.32692C20.4946 6.66383 20.7164 6.9555 21.0379 7.10076L23.6742 8.29198C24.2167 8.53715 24.4314 9.16802 24.1432 9.671L22.7435 12.1148C22.5728 12.4128 22.5728 12.7733 22.7435 13.0713L24.1432 15.515C24.4314 16.018 24.2167 16.6489 23.6742 16.8941L21.0379 18.0853C20.7164 18.2305 20.4946 18.5222 20.4492 18.8592L20.0778 21.622C20.0012 22.1906 19.4395 22.5805 18.8496 22.4742L15.9838 21.958C15.6343 21.895 15.2752 22.0064 15.0314 22.2536L13.0305 24.2802C12.6187 24.6973 11.9242 24.6973 11.5124 24.2802L9.51167 22.2536C9.26768 22.0064 8.90871 21.895 8.55924 21.958L5.6934 22.4742C5.10355 22.5805 4.5417 22.1906 4.46524 21.622L4.09376 18.8592C4.04847 18.5222 3.8266 18.2305 3.50514 18.0853L0.868881 16.8941C0.326278 16.6489 0.111671 16.018 0.399764 15.515L1.79947 13.0713C1.97016 12.7733 1.97016 12.4128 1.79947 12.1148L0.399765 9.671C0.111671 9.16802 0.326279 8.53715 0.868883 8.29198L3.50514 7.10076C3.8266 6.9555 4.04847 6.66383 4.09376 6.32692L4.46524 3.56406C4.5417 2.99539 5.10355 2.6055 5.6934 2.71176L8.55924 3.2281C8.90871 3.29106 9.26768 3.17966 9.51167 2.93252L11.5124 0.905867Z" fill="#3461FD" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2976 9.43353C17.5928 9.72411 17.5965 10.199 17.306 10.4942L11.3997 16.4942C11.1107 16.7878 10.6389 16.7933 10.3431 16.5065L7.24937 13.5064C6.95201 13.2181 6.94471 12.7433 7.23305 12.4459C7.52141 12.1486 7.99623 12.1412 8.29359 12.4296L10.8529 14.9114L16.2369 9.44189C16.5276 9.14669 17.0024 9.14295 17.2976 9.43353Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5267_41567">
                                            <rect width="24" height="24" fill="white" transform="translate(0.271484 0.593018)" />
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>

                        </div>
                    }
                    <p className='font-13 text-black-50 mt-1'>We’ve already verified your phone number</p>
                </div>
                <div>
                    <button type='button'
                        onClick={handleUpdateDetails}
                        className="w-100 btn-fill-blue p-3 rounded-3 font-15 fw-semibold ">{isLoading ? <Spinner size={'sm'} color='white' /> : 'Save changes'}</button>
                </div>
            </form>
            <ToastContainer />
        </>
    )
}

export default ManageDetails
