import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../assets/images/TimeLinkLogoBlackText.png'
import base_line from '../assets/images/Base__Box.png'
import { BsArrowLeft } from 'react-icons/bs'
import '../assets/css/getKnow.scss'
const GetKnow = () => {
    const Navigate = useNavigate();
    return (
        <>
            <div className="d-flex flex-column bg-white" style={{ minHeight: '100vh' }}>

                <div className="d-flex align-items-center w-100 justify-content-center " style={{ flexGrow: "3" }}>
                    <div>
                        {/* <Header1 /> */}
                        <div className="container Roobert-semibold">
                            <div className="row justify-content-center">
                                <div className="col-11">
                                    <div className="launnch-screen-content">
                                        <div className="mt-2">
                                            <h5 className="fw-semibold Roobert-semibold" style={{ fontSize: "28.8px" }}>Let’s get to know you</h5>



                                        </div>
                                        <form className="mt-0 num-form w-100 inter">
                                            <div className="w-100"
                                            >
                                                <div className='get-know'>
                                                    <input type="text" className="form-control mt-3 font-13 fw-normal "  placeholder="First Name" />
                                                    <input type="text" className="form-control mt-3 font-13 fw-normal "  placeholder="Last Name" />
                                                    <input type="text" className="form-control mt-3 font-13 fw-normal "  placeholder="Email Address" />
                                                </div>


                                                <img src={base_line} className='w-100 mt-2' alt="w8" />
                                            </div>

                                            <button
                                                className={`btn-fill-blue p-3 rounded-5  fw-normal w-100 mt-3`}

                                            >Next</button>
                                        </form>
                                    </div>
                                    <div className="d-flex align-items-center mt-3">
                                        <BsArrowLeft role='button' onClick={() => Navigate(-1)} size={28} className="text-gray" />                  <p
                                            className=" poppins mb-0 font-14 text-center flex-grow-1 text-blue"
                                         
                                        >
                                            skip

                                        </p>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                </div>
            <div className="text-end pb-3 pe-3">
                <img src={logo} width={150} alt="w8" />
            </div>
            </div>
        </>
    )
}

export default GetKnow
