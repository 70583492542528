import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import useMetaThemeColor from "../customHook/useMetaThemeColor";

import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  linkUserWithOrder,
  seTgetErrorNull,
  verifyUserOTP,
} from "../redux/slice/UserSlice";
const OtpNew = () => {
  const { getError, isLoading } = useSelector((state) => state.user);
  const [timer, setTimer] = useState(30);
  const [error, setError] = useState(false);
  const location = useLocation();
  const phone = location.state;
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");

  useMetaThemeColor("white");
  useEffect(() => {
    if (token) {
      navigate("/account");
    }
  }, []);
  useEffect(() => {
    if (timer === 0) return;
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  // useEffect(() => {
  //   if (getError && getError.length > 1) {
  //     toast.error(getError, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     dispatch(seTgetErrorNull());
  //   }
  // }, [getError, dispatch]);

  useEffect(() => {
    if (otp.length === 5) {
      let data = {
        otp: otp,
        phone: localStorage.getItem('phoneNumberForOtp')
      };
      dispatch(verifyUserOTP(data, handleResponse));
    }
    else{
      setError(false)
    }
  }, [otp, dispatch]);
  const handleResponse = (data) => {
    if (data?.status) {
      const isOrderFound = localStorage.getItem("isOrderFound");
      navigate(isOrderFound === "true" ? "/order" : "/account", {
        replace: true,
      });
      setError(false);
    } else {
      setError(true);
    }
  };
  const handleSubmit = () => {
    let businessKey = localStorage.getItem("businessKey");
    let orderId = localStorage.getItem("orderId");
    const phoneNumber = localStorage.getItem("phoneNumberForOtp");
    if (timer === 0 ) {
      
      if (phoneNumber == "") {
      } else {
        let data = {
          phone: phoneNumber,
          businessKey: businessKey,
          orderId: orderId,
        };
        dispatch(linkUserWithOrder(data, handleResponseResendOtp));
      }
    }
  };
  const handleResponseResendOtp = (response) => {
    if (response?.status) {
      console.log(response);
      toast.success("Otp sent again successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
    }
  };


  const renderInput = (inputProps, index) => {
    const isFilled = otp.length > index;
    const border = !isFilled ? "1.5px solid #E6E6E6":error ? "1.5px solid #D94029" :"1.5px solid #000000"
    return(
      <input inputMode="numeric" {...inputProps} style={{border}} className="w-16 h-16 rounded-lg text-center text-3xl font-semibold" />
    )
  };
  return (
    <>
    <div className="manrope p-3">
    <ToastContainer />
      <IoClose
        className="text-gray-600"
        size={20}
        onClick={() => navigate(-1)}
      />
      <div className="mt-7">
        <h5 className="font-semibold text-[27px]">Verify your number</h5>

        <p className=" font-14 text-gray-600">
          Enter the code sent to {phone}. <br />
          Wrong number? <strong>Use a different number</strong>
        </p>
      </div>
      <div className="my-8">
        <OTPInput
          value={otp}
          onChange={setOtp}
          className="gt"
          numInputs={5}
          renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
          // inputStyle={getInputStyle()}
          renderInput={renderInput}
          inputType="number"
        />
      </div>
      <p className="text-red-600 text-sm">
        {error && "The code you’ve entered is incorrect"}
      </p>
      <p className="mb-4 text-xs text-gray-500 underline underline-offset-4 ">
        <span onClick={handleSubmit} className={`${timer === 0 ? "cursor-pointer" : "cursor-not-allowed"}`}>
         
          Resend {" "}
        </span>
        {`${Math.floor(timer / 60)}:${String(timer % 60).padStart(2, "0")}`}
      </p>
    </div>
    <ToastContainer />
    </>
  );
};

export default OtpNew;
