/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import backgroundimg from "../assets/images/headerscren.png";
import finalLogo from "../assets/images/finalLogo.png";
import { IoChevronBackOutline } from "react-icons/io5";
import '../assets/css/loyalty.scss'
import OrderReadyStepper from "../components/OrderReadyStepper";
import { useEffect } from "react";
import { FiSearch } from 'react-icons/fi'
import img1 from '../assets/images/loyalty-img.png'
import bean from '../assets/images/bean.png'

import { Button, Modal, Typography } from "@mui/material";
import { AiTwotoneSetting } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { decode } from "base-64";
import { getCampaigns } from "../redux/slice/UserSlice";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
// };

const Loyalty = () => {
    const dispatch = useDispatch();
    const { orderDetail, isLoading, campaigns } = useSelector((state) => state.user);
    console.log(orderDetail?.business?.campaigns)
    const { bid: bId } = useParams();

    const navigate = useNavigate();
    const handelback = () => {
        navigate(-1);
    };
    const [showTopDiv, setShowTopDiv] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            if (bId) {
                setActiveTab(2);
                const dBIdBytes = decode(decodeURIComponent(bId));

                // Convert the decrypted bytes to strings
                const dBID = dBIdBytes.toString();

                dispatch(getCampaigns(dBID))
            } else {
                setActiveTab(1);
                dispatch(getCampaigns(0))

            }

        }


        fetchData(); // Call the async function
    }, []); // Empty dependency array to run this effect only once

    const handleScroll = (event) => {
        const scrollTop = event.target.scrollTop;
        setScrollPosition(scrollTop);

        if (scrollTop === 0) {
            setShowTopDiv(true);

        } else {
            setShowTopDiv(false);

        }
    };

    // ---------placeholder------text--change-------
    const [activeTab, setActiveTab] = useState(null);

    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);

    };
    return (
        <>
            <div className="container-fluid p-0 loyalty-page ">
                <img src={backgroundimg} className="feedback-Section w-100 " alt="" />
                {/* <Header3/> */}
                <div>

                    <div className="container d-flex justify-content-between align-items-center  my-4">
                        <button type="button" className='back_button' onClick={handelback}>{<IoChevronBackOutline />}</button>

                        <img src={finalLogo} width={160} alt="w8" onClick={() => navigate("/order")} />

                        <div>
                            <div onClick={() => navigate("/setting")} className="">

                                <div className="  text-white leftText ">***807</div>
                            </div>

                        </div>
                    </div>
                    {showTopDiv && (
                        <div className="top-div" style={{ transition: 'ease-in-out 4s' }}>

                            <OrderReadyStepper />
                        </div>
                    )}

                    <div className="container lato">

                        <div className="input-group mb-3">
                            <span className="input-group-text border-0 ms-2" id="basic-addon1"><FiSearch /></span>
                            <input type="text" className="form-control border-0 "
                                placeholder={`Search for ${activeTab == 1 ? 'Loyalty' : 'Offers'} programs...`} aria-describedby="basic-addon1" />
                        </div>

                    </div>
                    <div className="container px-4">

                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className={`nav-item ${activeTab == 1 ? "active" : ""
                                }`} role="presentation"
                            //  onClick={handleOpen}
                            >
                                <button className={`nav-link ${activeTab == 1 ? "active" : ""
                                    } font-12 lato `} onClick={() => handleTabChange(1)}
                                    id="pills-rewards-tab" data-bs-toggle="pill" data-bs-target="#pills-rewards" type="button" role="tab" aria-controls="pills-rewards" aria-selected="true">Loyalty rewards</button>
                            </li>

                            <li className={`nav-item ms-3 ${activeTab == 2 ? "active" : ""
                                }`} role="presentation">
                                <button className={`nav-link ${activeTab == 2 ? "active" : ""
                                    } font-12 lato `} onClick={() => handleTabChange(2)}
                                    id="pills-Offers-tab" data-bs-toggle="pill" data-bs-target="#pills-Offers" type="button" role="tab" aria-controls="pills-Offers" aria-selected="false">Offers</button>
                            </li>

                        </ul>
                    </div>
                </div>
                <section onScroll={handleScroll} style={{ height: `calc(100vh - ${showTopDiv ? '350px' : '208px'})`, overflowY: 'scroll' }}>

                    <div className="container    px-4">

                        <div className="tab-content scrollable-div pb-4" id="pills-tabContent"

                        >
                            <div className={`tab-pane fade ${activeTab == 1 ?'show active':''}`} id="pills-rewards" role="tabpanel" aria-labelledby="pills-rewards-tab" tabIndex={0}>
                                <div className="container">
                                    <div className="row justify-content-center">
                                        {orderDetail?.business?.campaigns?.length > 0 &&
                                            orderDetail?.business?.campaigns.map((val) => {
                                                return (
                                                    <div className="col-12 px-0 bg-white rounded-4 pb-4 mt-2" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }}>
                                                        <img src={orderDetail?.business?.cover_image} height={90} className="w-100 rounded-4 object-fit-contain" alt="w8" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }} />
                                                        <div className="d-flex align-items-center mt-2 justify-content-center">

                                                            <h6 className="lato font-18 fw-bold mb-0 pe-1" style={{ color: '#2F3542' }}>{orderDetail?.business?.business_name}</h6>
                                                            <p className="font-13 mb-0 lato ps-1  fw-bold " style={{ color: '#2F3542', borderLeft: '1.5px solid #2F3542' }}> {val?.title}</p>
                                                        </div>
                                                        <div className="loyalty-number mt-2">
                                                            <div>
                                                                <div className="font-12 lato fw-medium"><img src={bean} alt='w8'
                                                                    height={20} width={20} /></div>
                                                                <div className="font-12 lato fw-medium"><img src={bean} alt='w8'
                                                                    height={20} width={20} /></div>
                                                                <div className="font-12 lato fw-medium"><img src={bean} alt='w8'
                                                                    height={20} width={20} /></div>
                                                                <div className="font-12 lato fw-medium"><img src={bean} alt='w8'
                                                                    height={20} width={20} /></div>

                                                                <div className="font-12 lato fw-medium blue_bg text-white" >Free</div>
                                                            </div>
                                                            <div className="mt-1 ">
                                                                <div className="font-12 lato fw-medium"><img src={bean} alt='w8'
                                                                    height={20} width={20} /></div>
                                                                <div className="font-12 lato fw-medium"><img src={bean} alt='w8'
                                                                    height={20} width={20} /></div>
                                                                <div className="font-12 lato fw-medium"><img src={bean} alt='w8'
                                                                    height={20} width={20} /></div>
                                                                <div className="font-12 lato fw-medium"><img src={bean} alt='w8'
                                                                    height={20} width={20} /></div>
                                                                <div className="font-12 lato fw-medium black_bg text-white" >Free</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })

                                        }

                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade ${activeTab == 2 ?'show active':''}`} id="pills-Offers" role="tabpanel" aria-labelledby="pills-Offers-tab" tabIndex={0}>


                                <div className="container ">
                                    <div className="row justify-content-center">
                                        {
                                            campaigns &&
                                            campaigns.map((value, index) => {
                                                return (
                                                    <div className="col-12 px-0 bg-white rounded-4 pb-2" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }}>

                                                        <img src={value?.business?.image} height={90} className="w-100 rounded-4" alt="w8" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }} />
                                                        <div className="d-flex align-items-center mt-2 justify-content-center">

                                                            <h6 className="lato font-18 fw-bold mb-0 pe-1" style={{ color: '#2F3542' }}>{value?.business?.business_name} </h6>
                                                            <p className="font-13 mb-0 lato ps-1  fw-bold " style={{ color: '#2F3542', borderLeft: '1.5px solid #2F3542' }}> {value.offer_heading}</p>
                                                        </div>
                                                        <div className="container">

                                                            <div className="row mt-2 loyalty-offer justify-content-center">

                                                                <div className="col-7 text-center rounded-2">
                                                                    <h4 className="font-25 inter fw-semibold m-0"> {value?.id}</h4>
                                                                </div>
                                                                <div className="text-center">

                                                                    <Button onClick={() => navigate('/Qrcode', { state: { campaignData: value } })} variant="outlined" className="w-auto rounded-4 mt-2">View QR code</Button>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {/* <div className="col-12 px-0 bg-white rounded-4 pb-2 mt-3" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }}>

                                        <img src={img1} height={90} className="w-100 rounded-4" alt="w8" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }} />
                                        <div className="d-flex align-items-center mt-2 justify-content-center">

                                            <h6 className="lato font-18 fw-bold mb-0 pe-1" style={{ color: '#2F3542' }}>Kalz Brgr </h6>
                                            <p className="font-13 mb-0 lato ps-1  fw-bold " style={{ color: '#2F3542', borderLeft: '1.5px solid #2F3542' }}> Free Smoothie with every purchase</p>
                                        </div>
                                        <div className="container">

                                            <div className="row mt-2 loyalty-offer justify-content-center">

                                                <div className="col-7 text-center rounded-2">
                                                    <h4 className="font-25 inter fw-semibold m-0"> 817A</h4>
                                                </div>
                                                <div className="text-center">

                                                    <Button onClick={() => navigate('/Qrcode')} variant="outlined" className="w-auto rounded-4 mt-2">View QR code</Button>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 px-0 bg-white rounded-4 pb-2 mt-3" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }}>

                                        <img src={img1} height={90} className="w-100 rounded-4" alt="w8" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }} />
                                        <div className="d-flex align-items-center mt-2 justify-content-center">

                                            <h6 className="lato font-18 fw-bold mb-0 pe-1" style={{ color: '#2F3542' }}>Kalz Brgr </h6>
                                            <p className="font-13 mb-0 lato ps-1  fw-bold " style={{ color: '#2F3542', borderLeft: '1.5px solid #2F3542' }}> Free Smoothie with every purchase</p>
                                        </div>
                                        <div className="container">

                                            <div className="row mt-2 loyalty-offer justify-content-center">

                                                <div className="col-7 text-center rounded-2">
                                                    <h4 className="font-25 inter fw-semibold m-0"> 817A</h4>
                                                </div>
                                                <div className="text-center">

                                                    <Button onClick={() => navigate('/Qrcode')} variant="outlined" className="w-auto rounded-4 mt-2">View QR code</Button>
                                                </div>


                                            </div>
                                        </div>
                                    </div> */}

                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </section>
            </div>
        </>
    );
};

export default Loyalty;
