import React from 'react'
import { IoClose } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom';
import OrderCard from '../components/OrderCard';
import '../assets/css/versionThree.scss'
import img from '../assets/images/cafe.png'
import useAOS from '../customHook/useAOS';


const OrderHistryWithOutLogin = () => {
    useAOS();

    const navigate = useNavigate();
    const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
    const headerHeight = 59; // Assume header height is 56px
  
    React.useEffect(() => {
      function handleResize() {
        setScreenHeight(window.innerHeight);
      }
  
      window?.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <>
            <div data-aos="fade-left" data-aos-duration="500" className='position-fixed w-100 top-0 bg-white z-3' style={{ height: "59px",
            border:"1.5px solid #CACACA"  }}>
                <div className=' p-3 '>
                    <p className='font-15 fw-semibold mb-0  flex items-center'><IoClose size={18} onClick={() => navigate('/account')} />&nbsp;Order history</p>
                </div>
              

            </div>
            <section data-aos="fade-left" data-aos-duration="500" className='manrope flex-grow-1 d-flex align-items-end p-3' 
            style={{ height: `calc(${screenHeight}px - ${headerHeight}px)`, marginTop: "59px" }}>
                <div className='p-3 rounded-4 bg_blue'>
                    <svg width="60" height="53" viewBox="0 0 60 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_5263_28978)">
                            <path d="M39.375 2.8125H20.625C15.6522 2.8125 10.8831 4.78792 7.36676 8.30426C3.85043 11.8206 1.875 16.5897 1.875 21.5625C1.875 26.5353 3.85043 31.3044 7.36676 34.8207C10.8831 38.337 15.6522 40.3125 20.625 40.3125H22.5V49.6875L33.75 40.3125H39.375C44.3479 40.3125 49.1171 38.337 52.6331 34.8207C56.1495 31.3044 58.125 26.5353 58.125 21.5625C58.125 16.5897 56.1495 11.8206 52.6331 8.30426C49.1171 4.78792 44.3479 2.8125 39.375 2.8125ZM20.7188 23.5312C20.477 23.8536 20.1688 24.1203 19.815 24.3131C19.4612 24.506 19.0701 24.6206 18.6681 24.6492C18.2662 24.6777 17.8628 24.6196 17.4853 24.4787C17.1078 24.3378 16.7649 24.1174 16.48 23.8325C16.1951 23.5476 15.9747 23.2047 15.8338 22.8272C15.6929 22.4497 15.6348 22.0463 15.6633 21.6444C15.6919 21.2424 15.8065 20.8513 15.9994 20.4975C16.1922 20.1437 16.4589 19.8355 16.7812 19.5938C17.3178 19.0996 18.0206 18.8253 18.75 18.8253C19.4794 18.8253 20.1822 19.0996 20.7188 19.5938C21.2487 20.113 21.5519 20.8206 21.5625 21.5625C21.5519 22.3044 21.2487 23.012 20.7188 23.5312ZM31.9687 23.5312C31.4271 23.9375 30.7572 24.1347 30.0819 24.0867C29.4066 24.0387 28.7712 23.7487 28.2925 23.27C27.8138 22.7913 27.5238 22.1559 27.4758 21.4806C27.4278 20.8053 27.625 20.1354 28.0312 19.5938C28.5678 19.0996 29.2706 18.8253 30 18.8253C30.7294 18.8253 31.4322 19.0996 31.9687 19.5938C32.2381 19.8558 32.4521 20.1692 32.5983 20.5154C32.7445 20.8616 32.8198 21.2336 32.8198 21.6094C32.8198 21.9852 32.7445 22.3571 32.5983 22.7033C32.4521 23.0495 32.2381 23.3629 31.9687 23.625V23.5312ZM43.2188 23.5312C42.8257 23.9167 42.3281 24.1783 41.7878 24.2836C41.2474 24.3889 40.6879 24.3333 40.179 24.1236C39.6698 23.9141 39.2336 23.5596 38.9239 23.1043C38.6145 22.6491 38.4454 22.113 38.4375 21.5625C38.448 20.8206 38.7514 20.113 39.2812 19.5938C39.8179 19.0996 40.5206 18.8253 41.25 18.8253C41.9794 18.8253 42.6821 19.0996 43.2188 19.5938C43.734 20.1195 44.0228 20.8263 44.0228 21.5625C44.0228 22.2987 43.734 23.0055 43.2188 23.5312Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_5263_28978">
                                <rect width="60" height="52.5" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <p className='font-18 text-white fw-bold '>Log in or sign up to view your previous
                        orders.</p>
                    <p className='font-13 text-white fw-medium '>Save your recent orders & more with a timelink account.</p>
                    <Link to="/loginOrSignup">
                        <button type='button' className='w-100 bg-white p-3 rounded-3 font-15 fw-semibold text-blue'>Log in or sign up</button>
                    </Link>

                </div>
            </section>
            

        </>
    )
}

export default OrderHistryWithOutLogin
