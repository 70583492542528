import React, { memo } from 'react'
import img from '../assets/images/cafe.png'
import '../assets/css/versionThree.scss'

const OrderCard = (props) => {
    return (
        <div className='mt-3' style={{ border: "1.5px solid #f2f2f2", borderRadius: "22px" }}>
            <div className='position-relative  ' style={{ height: "120px", borderTopLeftRadius: "22px", borderTopRightRadius: "22px" }}>

                <img src={props.img} className='w-100  position-absolute top-0 start-0 w-100 object-fit-cover ' height="120" alt="w8" style={{ borderTopLeftRadius: "22px", borderTopRightRadius: "22px" }} />
                <div className='z-3 position-relative  h-100 w-100 d-flex align-items-center justify-content-center ' style={{ background: 'rgba(0, 0, 0, 0.5)', borderTopLeftRadius: "22px", borderTopRightRadius: "22px" }}>
                    <div className='manrope text-center'>

                        <h4 className='fw-semibold text-white'>{props.head}</h4>
                        <p className='fw-semibold font-13 mb-0 text-white'>{props.ref_id}</p>
                    </div>
                </div>
            </div>
            <div className='p-3 manrope bg-white' style={{ borderBottomLeftRadius: "22px", borderBottomRightRadius: "22px" }}>
                <div className='d-flex align-items-center justify-content-between '>
                    <p className='font-13 fw-semibold mb-0'>{props.date} • {props.time}</p>
                    {
                        props.loyaltyPoints &&
                        <p className='font-13 fw-semibold mb-0'>{"+"+props.loyaltyPoints+" points"}</p>
                    }

                </div>
                <li className='font-13 fw-semibold mb- mt-2 text-gray text-uppercase '>{props.status}</li>

            </div>

        </div>
    )
}

export default memo(OrderCard)
