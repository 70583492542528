import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
const Toaster = () => {
  return (
 <>
  <ToastContainer />
 </>
  )
}

export default Toaster