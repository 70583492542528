import React, { useEffect ,useState} from "react";
import backgroundimg from "../../assets/images/headerscren.png";
import finalLogo from "../../assets/images/finalLogo.png";
import "../../assets/css/loyalty.scss";

import { Button } from "@mui/material";
import { IoChevronBackOutline } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCampaignDetails, seTgetErrorNull, textMeLink } from "../../redux/slice/UserSlice";
import { decode, encode } from "base-64";
import { Spinner } from "reactstrap";
const style = {
  position: "fixed", // Change position to fixed
  bottom: 0, // Align modal to the bottom
  left: "50%",
  transform: "translateX(-50%)", // Center horizontally
  bgcolor: "background.paper",
  borderTopLeftRadius: "50px",
  borderTopRightRadius: "50px",
  maxHeight: "90vh", // Limit modal height to 90% of viewport height
  overflowY: "auto", // Add scroll when content overflows
};
const QrCode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {cid:cID} = useParams();

  const { isLoading, getError } = useSelector((state) => state.user);
  var user = JSON.parse(localStorage.getItem('userDetails'));
  const [campaign , setCampaign] = useState(location?.state?.campaignData)
  const handelback = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchData = async () => {
      const dCIdBytes = decode(decodeURIComponent(cID));

      // Convert the decrypted bytes to strings
      const dCId = dCIdBytes.toString();
      console.log(dCId, "===========campaign id received via link");
      if (dCId) {
        dispatch(getCampaignDetails(dCId , (data)=>{setCampaign(data)}))
      }
    };

    fetchData(); // Call the async function
  }, []);

  // useEffect(() => {
  //   if (getError && getError.length > 1) {
  //     toast.error(getError, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //     dispatch(seTgetErrorNull())
  //   }
  // }, [getError])
  // ---------modals------------
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalTwo, setOpenModalTwo] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(false);
    setOpenModalTwo(true);
  };
  const handlemodalOne = () => {
    setOpenModal(false);
  };
  const handlemodalTwo = () => {
    setOpenModalTwo(false);
  };
  const handleCloseTwo = () => {
    setOpenModalTwo(false);
    setOpenModal(false);
    navigate("/loyaltyTwo");
  };

  function handleTextMeALink(id) {
    console.log(id , '   encrypted campaign id')
    var data = {
      campaign_id:id
    }
    dispatch(textMeLink(data, handleResponse))
  }

  function handleResponse() {
    toast.success('Link has been sent successfully to your registered phone number', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  return (
    <>
      <div className="container-fluid p-0">
        <img
          src={backgroundimg}
          className="feedback-Section w-100"
          alt="Image Not Found"
        />
        <div className="container d-flex justify-content-between align-items-center  my-4">
          <button className="back_button" onClick={handelback}>
            {<IoChevronBackOutline />}
          </button>

          <img src={finalLogo} width={160} alt="w8" onClick={() => navigate("/order")} />

          <div
            className=" inter font-12 text-white leftText mt-2"
            style={{ textTransform: "capitalize" }} onClick={() => navigate("/setting")}
          >
            ***807
          </div>
        </div>

        <div className="container pb-3">
          <div className=" qrTEXT bg-white pt-4 pb-4 rounded-3 overflow-hidden">
            <div>
              <div className="d-flex align-items-center mt-2 justify-content-center">
                <h6
                  className="lato font-18 fw-bold mb-0 pe-2"
                  style={{ color: "#2F3542" }}
                >
                  {campaign?.business?.business_name}{" "}
                </h6>
                <p
                  className="font-14 mb-0 lato ps-2  fw-bold "
                  style={{
                    color: "#2F3542",
                    borderLeft: "1.5px solid #2F3542",
                  }}
                >
                  {" "}
                  {campaign?.offer_heading}
                </p>
              </div>
              <div className="container mt-4">
                <div className="row justify-content-center">
                  <div className="col-7 text-center">
                    <p
                      className="font-15 mb-0 montserrat fw-medium lh-1"
                      style={{
                        color: "#262626",
                      }}
                    >
                      {" "}
                      Show your QR code or provide your unique code to redeem.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="position-relative qr-code-circle">
              <span className="ok"></span>
              <span className="notok"></span>
            </div>
            <div className="container mt-4">
              <div className="qr text-center m-auto">
                {/* <img
                      src={qrcode}
                      alt=""
                      className="mt-2 "
                      height={150} 
                      width={150}
                    /> */}
                <QRCode
                  size={150}
                  value={JSON.stringify({ "user_id": user.id, "campaign_id": campaign?.id })}
                />
              </div>
            </div>
            <div className="container">
              <div className="row mt-2 QR-Code justify-content-center">
                <div className="col-8 text-center rounded-2 mt-2">
                  <h4 className="font-25 inter fw-semibold m-0"> {campaign?.id}</h4>
                </div>
                <div className="text-center">
                  <Button
                    variant="outlined"
                    className="w-auto rounded-4 mt-3 qr-button"
                    onClick={()=>{navigate('/offers')}}
                  >
                    View offers
                  </Button>
                </div>
                <div className="text-center">
                  <Button
                    onClick={()=>{handleTextMeALink(encodeURIComponent(encode(campaign?.id)))}}
                    variant="outlined"
                    className="w-auto rounded-4 mt-2 qr-button"
                  >
                    {isLoading? <Spinner color="black" size={'sm'}/>:'Text me a link'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default QrCode;
