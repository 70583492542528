/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import backgroundimg from "../../assets/images/headerscren.png";
import finalLogo from "../../assets/images/finalLogo.png";
import '../../assets/css/loyalty.scss'
// import OrderReadyStepper from "../components/OrderReadyStepper";
import { useEffect } from "react";
import { FiSearch } from 'react-icons/fi'
import img1 from '../../assets/images/loyalty-img.png'
import { Button } from "@mui/material";
import { useSelector , useDispatch} from "react-redux";
import { addClaimAbleLoyalty } from "../../redux/slice/UserSlice";

const LoyaltyTwo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { orderDetail, userDetail } = useSelector((state) => state.user);
    var user = JSON.parse(localStorage.getItem('userDetails'));
    // ---------placeholder------text--change-------
    const [activeTab, setActiveTab] = useState(1);

    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);

    };
    useEffect(() => {
        console.log(orderDetail, 'order detail')
        setTimeout(() => {
            if (orderDetail?.userLoyalties == orderDetail?.loyalty?.stmp_untl) {

                handleTabChange(2);
                handleAddClaimAbleLoyalty()
            }
        }, 2000);
    }, [orderDetail])

    function handleAddClaimAbleLoyalty(){
        var data = {
            order_id : orderDetail?.id,
            loyalty_id : orderDetail?.loyalty?.id,
        }
        dispatch(addClaimAbleLoyalty(data))
        
    }
    return (
        <>
            <div className="container-fluid p-0 loyalty-page ">
                <img src={backgroundimg} className="feedback-Section w-100 " alt="" />
                {/* <Header3/> */}
                <div>

                    <div className="container d-flex justify-content-between align-items-center  my-4">
                        <div
                            className=" inter font-12 text-white leftText mt-2"
                            style={{ textTransform: "capitalize" }} onClick={() => navigate("/setting")}
                        >

                            ***807
                        </div>

                        <img src={finalLogo} width={160} alt="w8" />

                        <div
                            className=" inter font-12 text-white leftText mt-2"
                            style={{ visibility: "hidden" }}
                        >

                            ***807
                        </div>
                    </div>


                    <div className="container lato">

                        <div className="input-group mb-3">
                            <span className="input-group-text border-0 ms-2" id="basic-addon1"><FiSearch /></span>
                            <input type="text" className="form-control border-0 "
                                placeholder={`Search for ${activeTab === 1 ? 'Loyalty' : 'Offers'} programs...`} aria-label="Username" aria-describedby="basic-addon1" />
                        </div>

                    </div>
                    {/* <div className="container">

                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className={`nav-item ${activeTab === 1 ? "active" : ""
                                }`} role="presentation"
                            >
                                <button className="nav-link active font-12 lato " onClick={() => handleTabChange(1)}
                                    id="pills-rewards-tab" data-bs-toggle="pill" data-bs-target="#pills-rewards-" type="button" role="tab" aria-controls="pills-rewards" aria-selected="true">Loyalty rewards</button>
                            </li>
                            <li className={`nav-item ms-3 ${activeTab === 2 ? "active" : ""
                                }`} role="presentation">
                                <button className="nav-link  font-12 lato " onClick={() => handleTabChange(2)}
                                    id="pills-Offers-tab" data-bs-toggle="pill" data-bs-target="#pills-Offers" type="button" role="tab" aria-controls="pills-Offers" aria-selected="false">Offers</button>
                            </li>

                        </ul>
                    </div> */}
                </div>
                
                   
                    <section style={{ height: `calc(100vh - 208px)`, overflowY: 'scroll' }}>

                        <div className="container px-4">

                            <div className=" scrollable-div pb-4" id="pills-tabContent"

                            >
                                {
                                    activeTab == 1 &&
                                    <div className={`tab-pane fade show active`} id="pills-rewards" role="tabpanel" aria-labelledby="pills-rewards-tab" tabIndex={0}>

                                    <div className="container ">
                                        <div className="row justify-content-center">
                                            <div className="col-12 px-0 bg-white rounded-4 pb-4" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }}>

                                                <img src={orderDetail?.businessData?.cover_image} height={90} className="w-100 rounded-4 object-fit-contain" alt="w8" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }} />
                                                <div className="d-flex align-items-center mt-2 justify-content-center">

                                                    <h6 className="lato font-18 fw-bold mb-0 pe-2" style={{ color: '#2F3542' }}>{orderDetail?.businessData?.business_name}</h6>
                                                    <p className="font-13 mb-0 lato ps-2  fw-bold " style={{ color: '#2F3542', borderLeft: '1.5px solid #2F3542' }}>{orderDetail?.loyalty?.description}</p>
                                                </div>
                                                <div className="loyalty-number mt-2">

                                                    <div>
                                                        {Array(Number(orderDetail?.loyalty?.stamp)).fill(null).map((value, index) => {
                                                            return (
                                                                <div className="font-12 lato fw-medium">{index < orderDetail?.userLoyalties ? <img className="w-100 h-100 rounded-circle" src={orderDetail?.loyalty?.stamp_icon} alt="stamp icon" /> : index + 1}</div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 px-0 bg-white rounded-4 pb-4 mt-3 " style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }}>

                                            <img src={img1} height={90} className="w-100 rounded-4" alt="w8" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }} />
                                            <div className="d-flex align-items-center mt-2 justify-content-center">

                                                <h6 className="lato font-18 fw-bold mb-0 pe-2" style={{ color: '#2F3542' }}>Kalz Brgr </h6>
                                                <p className="font-13 mb-0 lato ps-2  fw-bold " style={{ color: '#2F3542', borderLeft: '1.5px solid #2F3542' }}> Collect 5 stamps for a free coffee</p>
                                            </div>
                                            <div className="loyalty-number mt-2">

                                                <div>
                                                    <div className="font-12 lato fw-medium">1</div>
                                                    <div className="font-12 lato fw-medium">2</div>
                                                    <div className="font-12 lato fw-medium">3</div>
                                                    <div className="font-12 lato fw-medium">4</div>
                                                    <div className="font-12 lato fw-medium">5</div>
                                                </div>
                                                <div className="mt-1 ">
                                                    <div className="font-12 lato fw-medium">6</div>
                                                    <div className="font-12 lato fw-medium">7</div>
                                                    <div className="font-12 lato fw-medium">8</div>
                                                    <div className="font-12 lato fw-medium">9</div>
                                                    <div className="font-12 lato fw-medium">10</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 px-0 bg-white rounded-4 pb-4 mt-3 " style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }}>

                                            <img src={img1} height={90} className="w-100 rounded-4" alt="w8" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }} />
                                            <div className="d-flex align-items-center mt-2 justify-content-center">

                                                <h6 className="lato font-18 fw-bold mb-0 pe-2" style={{ color: '#2F3542' }}>Kalz Brgr </h6>
                                                <p className="font-13 mb-0 lato ps-2  fw-bold " style={{ color: '#2F3542', borderLeft: '1.5px solid #2F3542' }}> Collect 5 stamps for a free coffee</p>
                                            </div>
                                            <div className="loyalty-number mt-2">

                                                <div>
                                                    <div className="font-12 lato fw-medium">1</div>
                
                                                    <div className="font-12 lato fw-medium">2</div>
                                                    <div className="font-12 lato fw-medium">3</div>
                                                    <div className="font-12 lato fw-medium">4</div>
                                                    <div className="font-12 lato fw-medium">5</div>
                                                </div>
                                                <div className="mt-1 ">
                                                    <div className="font-12 lato fw-medium">6</div>
                                                    <div className="font-12 lato fw-medium">7</div>
                                                    <div className="font-12 lato fw-medium">8</div>
                                                    <div className="font-12 lato fw-medium">9</div>
                                                    <div className="font-12 lato fw-medium">10</div>
                                                </div>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                                }
                                {
                                    activeTab == 2 &&
                                <div className={`tab-pane fade show active `} id="pills-Offers" role="tabpanel" aria-labelledby="pills-Offers-tab" tabIndex={0}>

                                    <div className="container ">
                                        <div className="row justify-content-center">
                                            <div className="col-12 px-0 bg-white rounded-4 pb-2" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }}>

                                                <img src={orderDetail?.businessData?.cover_image} height={90} className="w-100 rounded-4" alt="w8" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }} />
                                                <div className="d-flex align-items-center mt-2 justify-content-center">

                                                    <h6 className="lato font-18 fw-bold mb-0 pe-1" style={{ color: '#2F3542' }}>{orderDetail?.businessData?.business_name} </h6>
                                                    <p className="font-13 mb-0 lato ps-1  fw-bold " style={{ color: '#2F3542', borderLeft: '1.5px solid #2F3542' }}> {orderDetail?.loyalty?.description}</p>
                                                </div>
                                                <div className="container">

                                                    <div className="row mt-2 loyalty-offer justify-content-center">

                                                        <div className="col-7 text-center rounded-2">
                                                            <h4 className="font-25 inter fw-semibold m-0"> {orderDetail?.loyalty?.id}</h4>
                                                        </div>
                                                        <div className="text-center">

                                                            <Button onClick={() => navigate('/qrCodeMarketing', { state: { loyalty_id: orderDetail?.loyalty?.id } })} variant="outlined" className="w-auto rounded-4 mt-2">View QR code</Button>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-12 px-0 bg-white rounded-4 pb-2 mt-3" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }}>

                                            <img src={img1} height={90} className="w-100 rounded-4" alt="w8" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }} />
                                            <div className="d-flex align-items-center mt-2 justify-content-center">

                                                <h6 className="lato font-18 fw-bold mb-0 pe-1" style={{ color: '#2F3542' }}>Kalz Brgr </h6>
                                                <p className="font-13 mb-0 lato ps-1  fw-bold " style={{ color: '#2F3542', borderLeft: '1.5px solid #2F3542' }}> Free Smoothie with every purchase</p>
                                            </div>
                                            <div className="container">

                                                <div className="row mt-2 loyalty-offer justify-content-center">

                                                    <div className="col-7 text-center rounded-2">
                                                        <h4 className="font-25 inter fw-semibold m-0"> 817A</h4>
                                                    </div>
                                                    <div className="text-center">

                                                        <Button onClick={() => navigate('/qrCodeMarketing')} variant="outlined" className="w-auto rounded-4 mt-2">View QR code</Button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 px-0 bg-white rounded-4 pb-2 mt-3" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }}>

                                            <img src={img1} height={90} className="w-100 rounded-4" alt="w8" style={{ boxShadow: '0px 10px 20px 0px rgb(47 53 66 / 10%)' }} />
                                            <div className="d-flex align-items-center mt-2 justify-content-center">

                                                <h6 className="lato font-18 fw-bold mb-0 pe-1" style={{ color: '#2F3542' }}>Kalz Brgr </h6>
                                                <p className="font-13 mb-0 lato ps-1  fw-bold " style={{ color: '#2F3542', borderLeft: '1.5px solid #2F3542' }}> Free Smoothie with every purchase</p>
                                            </div>
                                            <div className="container">

                                                <div className="row mt-2 loyalty-offer justify-content-center">

                                                    <div className="col-7 text-center rounded-2">
                                                        <h4 className="font-25 inter fw-semibold m-0"> 817A</h4>
                                                    </div>
                                                    <div className="text-center">

                                                        <Button onClick={() => navigate('/qrCodeMarketing')} variant="outlined" className="w-auto rounded-4 mt-2">View QR code</Button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>


                        </div>
                    </section>
                
            </div>
        </>
    );
};

export default LoyaltyTwo;
