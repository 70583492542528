import { createSlice } from '@reduxjs/toolkit'
import { dispatch } from '../store/store'
import axios from '../../utils/axiosBaseUrl';

const initialState = {
  isLoading: false,
  getError: null,
  userDetail: {},
}

const SurveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    //START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    //STOP LOADING
    stopLoading(state) {
      state.isLoading = false
    },

    // HAS UPDATE ERROR
    hasGetError(state, action) {
      state.isLoading = false
      state.getError = action.payload
    },
    // GET User DETAILS
    getUserDetailsSuccess(state, action) {
      state.isLoading = false;
      state.userDetail = action.payload
    },
  },
})
export const { getUserDetailsSuccess } = SurveySlice.actions;
export default SurveySlice.reducer

// User Functions

//User SurveyRecommended
export function SurveyRecommended(data, handleClose) {
  return async () => {
    dispatch(SurveySlice.actions.startLoading());
    try {
      const response = await axios.post('user/login',
        data, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      });
      handleClose(response.data);
      if (!response.data.status) {
        dispatch(SurveySlice.actions.hasGetError(response?.data?.message));
      }
      console.log(JSON.stringify(response?.data?.data))
      dispatch(SurveySlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error);
      dispatch(SurveySlice.actions.hasGetError(error?.message));
    }
  };
}
//User SurveyAge
export function SurveyAge(data, handleClose) {
  return async () => {
    dispatch(SurveySlice.actions.startLoading());
    try {
      const response = await axios.post('user/login',
        data, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      });
      handleClose(response.data);
      if (!response.data.status) {
        dispatch(SurveySlice.actions.hasGetError(response?.data?.message));
      }
      console.log(JSON.stringify(response?.data?.data))
      dispatch(SurveySlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error);
      dispatch(SurveySlice.actions.hasGetError(error?.message));
    }
  };
}
//User SurveyGender
export function SurveyGender(data, handleClose) {
  return async () => {
    dispatch(SurveySlice.actions.startLoading());
    try {
      const response = await axios.post('user/login',
        data, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
      });
      handleClose(response.data);
      if (!response.data.status) {
        dispatch(SurveySlice.actions.hasGetError(response?.data?.message));
      }
      console.log(JSON.stringify(response?.data?.data))
      dispatch(SurveySlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {
      handleClose(error);
      dispatch(SurveySlice.actions.hasGetError(error?.message));
    }
  };
}

//Get User Details
export function getUser() {
  return async () => {
    let accessToken = localStorage.getItem('accessToken')
    dispatch(SurveySlice.actions.startLoading());
    try {
      const response = await axios.get('user', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        }
      });

      dispatch(SurveySlice.actions.getUserDetailsSuccess(response.data.data));
    } catch (error) {

      dispatch(SurveySlice.actions.hasGetError(error?.message));
    }
  };
}