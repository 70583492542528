import React from "react";
import lunchim from "../assets/images/ellipse2.png";
import user from "../assets/images/mobile.svg";
import finalLogo from "../assets/images/finalLogo.png"
const Header1 = (props) => {
  return (
    <>
      <div className="container-fluid  position-relative p-0">
        <div className=" d-flex pb-2">
          <img src={lunchim} alt="" srcset="" className="launchscreen w-100" style={{height:'105px'}} />
          <div className="transparent-logo mx-auto mt-3">
          <img src={finalLogo} width={160} alt="" />
          </div>
        </div>
        <div className="backgroundwhite2 mt-5  " style={{display:props.dblock}}>
          <img src={user} width={45} height={45} alt="" />
        </div>
      </div>
    

    </>
  );
};

export default Header1;
