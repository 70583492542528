import React from 'react'
import GoggleReview from "../../assets/images/GoggleReview.png";
import goggle from "../../assets/images/GoggleG.png";
import { Button } from '@mui/material';
import logo from '../../assets/images/TimeLinkLogoBlackText.png'

const GoogleReview = () => {
    return (
        <>
            <div className="container d-flex flex-column bg-white  justify-content-between  " style={{ minHeight: "100vh" }}>
                <div className=" text-center mt-3">

                    <img src={logo} width={150} alt="" />
                </div>
                <div className="p-3 pb-0 pt-0 flex-grow-1 d-flex align-items-center ">
                    <div className="w-100">

                        <div className="row justify-content-center ">
                            <div className="col-8 my-4">

                                <img src={GoggleReview} className="w-100" alt="w8" />

                            </div>
                            <p
                                className="font-13 mb-4 text-center fw-medium inter"
                                style={{ color: "#5B5B5B" }}
                            >Your feedback goes a long way! How about  <br /> a google review?</p>
                        </div>

                        <div className="mt-3 row justify-content-center mb-4">
                            <div className="col-12">
                                <a
                                    href="#" target="_blank"
                                    rel="noreferrer"
                                >

                                    <Button
                                        type="button"
                                        className="btn-border px-2 py-1 rounded-3 justify-content-between w-100 align-items-center text-white mx-auto font-14-69 montserrat"

                                    >
                                        <img src={goggle} alt="w8" width={50} height={50} className="h-100 px-1" />
                                        <p className="mb-0 font-14 inter" >Google Review</p>
                                        <img src={goggle} alt="w8" width={50} height={50} className="px-1" style={{ visibility: 'hidden' }} />

                                    </Button>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GoogleReview
