import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Bottomcom from "./Bottomcom";
import Header1 from "./Header1";
import elipsebotom from "../assets/images/elipse3.png";

const RefrencePageComponent = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");

  // Function to handle input changes
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Function to handle button click and navigate to the next page
  const handleButtonClick = () => {
    if (inputValue.length >= 4) {
      navigate("launchscreen");
    }
  };

  // Determine the button color based on input length
  const buttonColor = inputValue.length >= 4 ? "blues" : "grays";

  return (
    <>
      <div
        className="d-flex justify-content-between flex-column"
        style={{ minHeight: "100vh" }}
      >
        <div>
          <Header1 />
          <div className="container">
            <div className="launnch-screen-content">
              <div className="mt-4">
                <h5 className="font-23">What’s your Reference ID?</h5>
                <div className="row justify-content-center">
                  <div className="col-11">
                    <p className="mb-4 font-12-86">
                      Please enter the Reference ID, If you’re unsure please
                      check with the store.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 px-4">
              <input
                type="text"
                placeholder="Reference ID"
                className="w-100 border-0 refIdInput"
                value={inputValue}
                onChange={handleInputChange}
                onFocus={() => setInputValue("")} // Clear input value when focused
              />
              {inputValue.length > 0 && ( // Show the button when the input value is not empty
                <button
                  className={`my-button mt-3 ${buttonColor}`}
                  onClick={handleButtonClick}
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="position-relative">
          <img
            src={elipsebotom}
            alt=""
            className="w-100"
            style={{ height: "calc(100vh - 427px)" }}
          />
          <p className="signin-text">@2023 Time Link Tech PTY LTD</p>
        </div>
      </div>
    </>
  );
};

export default RefrencePageComponent;
