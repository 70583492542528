import React from "react";
import RefrencePageComponent from "../../components/RefrencePageComponent";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const RefrencePage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  return (
    <>
      <RefrencePageComponent />
    </>
  );
};

export default RefrencePage;
